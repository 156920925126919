import * as React  from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
 

import Logo from "./../assets/images/covenant-logo.png"
import BlackLogo from "./../assets/images/convenant-logo-black.png"
import MSLogo from "./../assets/images/microsft-logo.png"
import LogoPDF from './../assets/images/pdf.png'
import graphicsDeS from './../assets/images/Cyberinsurance-infographics.jpg'
import graphicsDe from './../assets/images/image.jpg'
import { jsPDF } from "jspdf";
import axios from "axios";
import { API_URL } from '../config/constant';
//import DatePicker from "react-multi-date-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment' 
export default function Roi(){
	ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);
	const [currentEnvOption, setCurrentEnvOption] = React.useState([])
	const [updatedEnvOption, setUpdatedEnvOption] = React.useState([])
	const [aeOption, setAeOption] = React.useState([])
	const [quoteNumber, setQuoteNumber] = React.useState("")
	const [companyName, setCompanyName] = React.useState("")
	const [companyNameError, setCompanyNameError] = React.useState(false)
	const [covenantAEName, setCovenantAEName] = React.useState("")
	const [covenantAENameError, setCovenantAENameError] = React.useState(false)
	const [createdDate, setCreatedDate] = React.useState(new Date())
	const [createdDateError, setCreatedDateError] = React.useState(false)
	const [customerName, setCustomerName] = React.useState("")
	const [customerNameError, setCustomerNameError] = React.useState(false)
	const [backup, setBackup] = React.useState("")
	const [backupPerUser, setBackupPerUser] = React.useState("")
	const [soc, setSoc] = React.useState("")
	const [socPerUser, setSocPerUser] = React.useState("")
	const [technicalName, setTechnicalName] = React.useState("")
	const [technicalNameError, setTechnicalNameError] = React.useState(false)
	const [customerTitle, setCustomerTitle] = React.useState("")
	const [customerTitleError, setCustomerTitleError] = React.useState(false)
	const [technicalContact, setTechnicalContact] = React.useState("")
	const [technicalContactError, setTechnicalContactError] = React.useState(false)
	const [customerContact, setCustomerContact] = React.useState("")
	const [customerContactError, setCustomerContactError] = React.useState(false)
	const [startDate, setStartDate] = React.useState("")
	const [startDateError, setStartDateError] = React.useState(false)
	const [noOfUsers, setNoOfUsers] = React.useState("1")
	const [solutionOneText, setSolutionOneText] = React.useState("")
	const [solutionOneValueMonthly, setSolutionOneValueMonthly] = React.useState(0)
	const [solutionOneValueYearly, setSolutionOneValueYearly] = React.useState(0)
	const [solutionTwoText, setSolutionTwoText] = React.useState("")
	const [solutionTwoValueMonthly, setSolutionTwoValueMonthly] = React.useState(0)
	const [solutionTwoValueYearly, setSolutionTwoValueYearly] = React.useState(0)
	const [solutionThreeText, setSolutionThreeText] = React.useState("")
	const [solutionThreeValueMonthly, setSolutionThreeValueMonthly] = React.useState(0)
	const [solutionThreeValueYearly, setSolutionThreeValueYearly] = React.useState(0)
	const [solutionFourText, setSolutionFourText] = React.useState("")
	const [solutionFourValueMonthly, setSolutionFourValueMonthly] = React.useState(0)
	const [solutionFourValueYearly, setSolutionFourValueYearly] = React.useState(0)
	const [solutionFiveText, setSolutionFiveText] = React.useState("")
	const [solutionFiveValueMonthly, setSolutionFiveValueMonthly] = React.useState(0)
	const [solutionFiveValueYearly, setSolutionFiveValueYearly] = React.useState(0)
	const [solutionElevenText, setSolutionElevenText] = React.useState("")
	const [solutionElevenValueMonthly, setSolutionElevenValueMonthly] = React.useState(0)
	const [solutionElevenValueYearly, setSolutionElevenValueYearly] = React.useState(0)
	const [solutionTwelveText, setSolutionTwelveText] = React.useState("")
	const [solutionTwelveValueMonthly, setSolutionTwelveValueMonthly] = React.useState(0)
	const [solutionTwelveValueYearly, setSolutionTwelveValueYearly] = React.useState(0)
	const [solutionSixText, setSolutionSixText] = React.useState("")
	const [solutionSixValueMonthly, setSolutionSixValueMonthly] = React.useState(0)
	const [solutionSixValueYearly, setSolutionSixValueYearly] = React.useState(0)
	const [solutionSevenText, setSolutionSevenText] = React.useState("")
	const [solutionSevenValueMonthly, setSolutionSevenValueMonthly] = React.useState(0)
	const [solutionSevenValueYearly, setSolutionSevenValueYearly] = React.useState(0)
	const [solutionEightText, setSolutionEightText] = React.useState("")
	const [solutionEightValueMonthly, setSolutionEightValueMonthly] = React.useState(0)
	const [solutionEightValueYearly, setSolutionEightValueYearly] = React.useState(0)
	const [solutionNineText, setSolutionNineText] = React.useState("")
	const [solutionNineValueMonthly, setSolutionNineValueMonthly] = React.useState(0)
	const [solutionNineValueYearly, setSolutionNineValueYearly] = React.useState(0)
	const [solutionTenText, setSolutionTenText] = React.useState("")
	const [solutionTenValueMonthly, setSolutionTenValueMonthly] = React.useState(0)
	const [solutionTenValueYearly, setSolutionTenValueYearly] = React.useState(0)
	const [totalMonthlyCost, setTotalMonthlyCost] = React.useState(0)
	const [totalYearlyCost, setTotalYearlyCost] = React.useState(0)
	const [totalUsersMonthlyCost, setTotalUsersMonthlyCost] = React.useState(0)
	const [totalUsersYearlyCost, setTotalUsersYearlyCost] = React.useState(0)
	const [updateInvoiveNumber, setUpdateInvoiveNumber] = React.useState("")
	const [altSolutionOneText, setAltSolutionOneText] = React.useState("")
	const [altSolutionOneValueMonthly, setAltSolutionOneValueMonthly] = React.useState(0)
	const [altSolutionOneValueYearly, setAltSolutionOneValueYearly] = React.useState(0)
	const [textAreaBlock, setTextAreaBlock] = React.useState("")
	const [altTotalMonthlyCost, setAltTotalMonthlyCost] = React.useState(0)
	const [altTotalYearlyCost, setAltTotalYearlyCost] = React.useState(0)
	const [altTotalUsersMonthlyCost, setAltTotalUsersMonthlyCost] = React.useState(0)
	const [altTotalUsersYearlyCost, setAltTotalUsersYearlyCost] = React.useState(0)
	const [monthlySaving, setMonthlySaving] = React.useState(0)
	const [yearlySaving, setYearlySaving] = React.useState(0)
	const [cyberPremium, setCyberPremium] = React.useState("")
	const [apiPolicyDis, setApiPolicyDis] = React.useState("5")
	const [apiPolicyDisValue, setApiPolicyDisValue] = React.useState("")
	const [lowRiskPremium, setLowRiskPremium] = React.useState("")
	const [lowRiskPremiumVal, setLowRiskPremiumVal] = React.useState("")
	const [annualROI, setAnnualROI] = React.useState("")
	const [eMSaving, setEMSaviving] = React.useState(75)
	const [fortifySaving, setFortifySaviving] = React.useState(7500)
	const [lowRiskError, setLowRiskError] = React.useState(false)
	const [aeNameLabel,setAeNameLabel] = React.useState("");
    const [dataOfCEForPdf,setDataOfCEForPdf]=React.useState([]);
    const [dataOfUEForPdf,setDataOfUEForPdf]=React.useState([]);
	const [eLRP,setELRP] = React.useState("");
	const [additional, setAdditional] = React.useState("")
	const [additionalText, setAdditionalText] = React.useState("")

	const currencyFormat = (num, symbol) => {
	   return ((symbol)?symbol:"")+num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}
	const currencyUnformat = (formattedString) => {
	    const cleanedString = (formattedString.toString()).replace(/[^\d.]/g, '');
	    return parseFloat(cleanedString);
	}
	const handleCompanyName = (e) => {
		setCompanyNameError(false)
		setCompanyName(e.target.value)
	}
	const handleAEName = (e) => {
		let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
		setAeNameLabel(label);

		setCovenantAEName(e.target.value)
		setCovenantAENameError(false)
	}
	const handleCreatedDate = (e) => {
		//console.log(e)
		setCreatedDateError(false)
		setCreatedDate(e)
	}
	const handleCustomerName = (e) => {
		setCustomerName(e.target.value)
		setCustomerNameError(false)
	}
	const handleTechnicalName = (e) => {
		setTechnicalName(e.target.value)
		setTechnicalNameError(false)
	}
	const handleCustomerTitle = (e) => {
		setCustomerTitle(e.target.value)
		setCustomerTitleError(false)
	}
	const handleTechnicalConctact = (e) => {
		setTechnicalContact(e.target.value)
		setTechnicalContactError(false)
	}
	const handleCustomerContact = (e) => {
		setCustomerContact(e.target.value)
		setCustomerContactError(false)
	}
	const handleStartDate = (e) => {
		setStartDate(e)
		setStartDateError(false)
	}
	const handleNoOfUsers = (e) => {
		setNoOfUsers(e.target.value)
		if(e.target.value){
			setEMSaviving(e.target.value*75)
		}else{
			setEMSaviving(75)
		}
		
		if(e.target.value <= 50){
			setFortifySaviving(7500)
		}
		if(e.target.value > 50 && e.target.value <= 100){
			setFortifySaviving(10000)
		}
		if(e.target.value > 100 && e.target.value <= 200){
			setFortifySaviving(15000)
		}
		if(e.target.value > 200){
			setFortifySaviving(20000)
		}
	}
	//no of user
	React.useEffect(() => {
		if(noOfUsers > 0){
			setSolutionOneValueYearly(currencyFormat(solutionOneValueMonthly*noOfUsers))
			setSolutionTwoValueYearly(currencyFormat(solutionTwoValueMonthly*noOfUsers))
			setSolutionThreeValueYearly(currencyFormat(solutionThreeValueMonthly*noOfUsers))
			setSolutionFourValueYearly(currencyFormat(solutionFourValueMonthly*noOfUsers))
			setSolutionFiveValueYearly(currencyFormat(solutionFiveValueMonthly*noOfUsers))
			setSolutionElevenValueYearly(currencyFormat(solutionElevenValueMonthly*noOfUsers))
			setSolutionTwelveValueYearly(currencyFormat(solutionTwelveValueMonthly*noOfUsers))
			setSolutionSixValueYearly(currencyFormat(solutionSixValueMonthly*noOfUsers))
			setSolutionSevenValueYearly(currencyFormat(solutionSevenValueMonthly*noOfUsers))
			setSolutionEightValueYearly(currencyFormat(solutionEightValueMonthly*noOfUsers))
			setSolutionNineValueYearly(currencyFormat(solutionNineValueMonthly*noOfUsers))
			setSolutionTenValueYearly(currencyFormat(solutionTenValueMonthly*noOfUsers))
			setAltSolutionOneValueYearly(currencyFormat(altSolutionOneValueMonthly*noOfUsers))
			setBackup(currencyFormat(backupPerUser*noOfUsers))
			setSoc(currencyFormat(socPerUser*noOfUsers))
		} 
	},[noOfUsers])
	
	React.useEffect(() => {
		/*if(solutionOneValueMonthly || 
		solutionTwoValueMonthly || 
		solutionThreeValueMonthly || 
		solutionFourValueMonthly || 
		solutionFiveValueMonthly || 
		solutionSixValueMonthly || 
		solutionSevenValueMonthly || 
		solutionEightValueMonthly || 
		solutionNineValueMonthly || solutionTenValueMonthly){*/
		if(solutionOneValueMonthly){
			filterFunc(["Productivity Applications",solutionOneText?solutionOneText:"","$ "+currencyFormat(solutionOneValueMonthly?solutionOneValueMonthly*noOfUsers:0)])
		}
		if(solutionTwoValueMonthly){
			filterFunc(["Multi-Factor Authentication (MFA/2FA)",solutionTwoText?solutionTwoText:"","$ "+currencyFormat(solutionTwoValueMonthly?solutionTwoValueMonthly*noOfUsers:0)])
		}
		if(solutionThreeValueMonthly){
			filterFunc(["Single Sign-On (SSO)",solutionThreeText?solutionThreeText:"","$ "+currencyFormat(solutionThreeValueMonthly?solutionThreeValueMonthly*noOfUsers:0)])
		}
		if(solutionFourValueMonthly){
			filterFunc(["Cloud Storage",solutionFourText?solutionFourText:"","$ "+currencyFormat(solutionFourValueMonthly?solutionFourValueMonthly*noOfUsers:0)])
		}
		if(solutionFiveValueMonthly){
			filterFunc(["Chat",solutionFiveText?solutionFiveText:"","$ "+currencyFormat(solutionFiveValueMonthly?solutionFiveValueMonthly*noOfUsers:0)])
		}
		if(solutionElevenValueMonthly){
			filterFunc(["Video Conference",solutionElevenText?solutionElevenText:"","$ "+currencyFormat(solutionElevenValueMonthly?solutionElevenValueMonthly*noOfUsers:0)])
		}

		if(solutionTwelveValueMonthly){
			filterFunc(["Backup",solutionTwelveText?solutionTwelveText:"","$ "+currencyFormat(solutionTwelveValueMonthly?solutionTwelveValueMonthly*noOfUsers:0)])
		}
		if(solutionSixValueMonthly){
			filterFunc(["Email Threat Protection",solutionSixText?solutionSixText:"","$ "+currencyFormat(solutionSixValueMonthly?solutionSixValueMonthly*noOfUsers:0)])
		}
		if(solutionSevenValueMonthly){
			filterFunc(["Endpoint Antivirus/AntiMalware (EDR/XDR)",solutionSevenText?solutionSevenText:"","$ "+currencyFormat(solutionSevenValueMonthly?solutionSevenValueMonthly*noOfUsers:0)])
		}
		if(solutionEightValueMonthly){
			filterFunc(["Endpoint Management (MDM)",solutionEightText?solutionEightText:"","$ "+currencyFormat(solutionEightValueMonthly?solutionEightValueMonthly*noOfUsers:0)])
		}
		if(solutionNineValueMonthly){
			filterFunc(["24x7 Security Operations Center (SOC) + MDR",solutionNineText?solutionNineText:"","$ "+currencyFormat(solutionNineValueMonthly?solutionNineValueMonthly*noOfUsers:0)])
		}
		if(solutionTenValueMonthly){
			filterFunc(["Additional",solutionTenText?solutionTenText:"","$ "+currencyFormat(solutionTenValueMonthly?solutionTenValueMonthly*noOfUsers:0)])
		}
		if(altSolutionOneValueMonthly){
			filterFuncAlt(["Updated Environment", altSolutionOneText,"$ "+currencyFormat(altSolutionOneValueMonthly?altSolutionOneValueMonthly*noOfUsers:0)]);
		}
		if(backupPerUser){
			let dtuu = "";
			if(backupPerUser != "0"){
				dtuu = ["Backup (365 Tenant)", "Backup (365 Tenant) - Acronis","$ "+currencyFormat(backupPerUser?parseFloat(backupPerUser*noOfUsers):0)];
			}else{
				dtuu = ["Backup (365 Tenant)", "Backup (365 Tenant) - None","$ "+currencyFormat(0)];
			}
			filterFuncAlt(dtuu);
		}
		if(socPerUser){
			let dtuu = "";
			if(socPerUser != "0"){
				dtuu = ["24x7 Security Operations Center (SOC) + MDR", "24x7 Security Operations Center (SOC) + MDR - Blackpoint Cyber","$ "+currencyFormat(socPerUser?parseFloat(socPerUser*noOfUsers):0)];
			}else{
				dtuu = ["24x7 Security Operations Center (SOC) + MDR", "MDR(Managed Detection Response ) - None","$ "+currencyFormat(0)];
			}
			filterFuncAlt(dtuu);
		}
	},[solutionOneValueMonthly,
		solutionTwoValueMonthly,
		solutionThreeValueMonthly,
		solutionFourValueMonthly,
		solutionFiveValueMonthly,
		solutionElevenValueMonthly,
		solutionTwelveValueMonthly,
		solutionSixValueMonthly,
		solutionSevenValueMonthly,
		solutionEightValueMonthly,
		solutionNineValueMonthly,
		solutionTenValueMonthly, noOfUsers, altSolutionOneValueMonthly, backupPerUser, socPerUser])
		
	const handleAdditional = (e) => {
		setAdditional(e)
		let alt_dd=["Additional",additionalText,"$ "+currencyFormat(e?parseFloat(e):0)];
		filterFunc(alt_dd);
	}
	const handleAdditionalText = (e) => {
		setAdditionalText(e.target.value)
		let alt_dd=["Additional",e.target.value,"$ "+currencyFormat(additional?parseFloat(additional):0)];
		filterFunc(alt_dd);
	}
	const [isDisableOneCurrent, setIsDisableOneCurrent] = React.useState(true)
	const [isDisableTwoCurrent, setIsDisableTwoCurrent] = React.useState(true)
	const [isDisableThreeCurrent, setIsDisableThreeCurrent] = React.useState(true)
	const [isDisableFourCurrent, setIsDisableFourCurrent] = React.useState(true)
	const [isDisableFiveCurrent, setIsDisableFiveCurrent] = React.useState(true)
	const [isDisableElevenCurrent, setIsDisableElevenCurrent] = React.useState(true)
	const [isDisableTwelveCurrent, setIsDisableTwelveCurrent] = React.useState(true)
	const [isDisableSixCurrent, setIsDisableSixCurrent] = React.useState(true)
	const [isDisableSevenCurrent, setIsDisableSevenCurrent] = React.useState(true)
	const [isDisableEightCurrent, setIsDisableEightCurrent] = React.useState(true)
	const [isDisableNineCurrent, setIsDisableNineCurrent] = React.useState(true)
	const [isDisableTenCurrent, setIsDisableTenCurrent] = React.useState(true)
	const chartRef = React.useRef(null);
	const [selectNone,setSelectNone]=React.useState({
		one:false,
		two:false,
		three:false,
		four:false,
		five:false,
		six:false,
		seven:false,
		eight:false,
		nine:false, 
		eleven:false,
		twelve:false})
		const [chartDataAr,setChartDataAr]=React.useState([]);
		const [chartLabel,setChartLabel]=React.useState([]);
		const [chartMaxValue,setChartMaxValue]=React.useState([]);
		const [chartDataValue,setChartDataValue]=React.useState([]);
		const addObjectToChartArray = (newObject) => {
			setChartDataAr(prevArray => { 
				 const labelExists = prevArray.some(item => item.label === newObject.label); 
			  if (labelExists) { 
				return prevArray.map(item => 
				  item.label === newObject.label ? newObject : item
				);
			  } else { 
				return [...prevArray, newObject];
			  }
			});
		  };  
		  const removeItemById = (id) => {
			setChartDataAr(prevArray => {
			  const itemExists = prevArray.some(item => item.id === id);
			  if (itemExists) {
				return prevArray.filter(item => item.id !== id);
			  }
			  return prevArray;
			});
		  };
		  React.useEffect(() => {
			const sortDataById = (data) => {
				return data.sort((a, b) => a.id - b.id);
			  };
		  
			  // Sort and update the state
			  const sortedData = sortDataById([...chartDataAr]); // Spread operator to avoid mutating the original state
			  setChartDataAr(sortedData);
			// Extract values from chartDataAr
			const labels = chartDataAr.map(item => item.label);
			const maxValues = chartDataAr.map(item => item.maxVal);
			const dataValues = chartDataAr.map(item => item.value); 
			setChartLabel(labels);
			setChartMaxValue(maxValues);
			setChartDataValue(dataValues);
		  }, [chartDataAr]); 
		  const ChartDataDisplaydata = {
			labels: chartLabel, // X-axis labels
			datasets: [
			  {
				label: 'Max Value',
				data:chartMaxValue, // High values
				borderColor: '#fb8e01',
				backgroundColor: 'rgba(75, 192, 192, 0.2)',
				fill: false,
				tension: 0.1
			  },
			  {
				label: 'Value',
				data:chartDataValue, // Low values
				borderColor: 'blue',
				backgroundColor: 'rgba(255, 99, 132, 0.2)',
				fill: false,
				tension: 0.1
			  }
			]
		  };
		
		  // Options for the chart 
		  const ChartDataDisplayOptions = {
			responsive: true,
			plugins: {
			  legend: {
				position: 'top',
			  },
			  tooltip: {
				callbacks: {
				  label: function(tooltipItem) {
					return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
				  }
				}
			  }
			},
			scales: {
			  x: {
				title: {
				  display: true,
				  text: ''
				}
			  },
			  y: {
				title: {
				  display: false,
				  text: 'Value'
				},
				min: -2,
				max: 10
			  }
			}
		  };
		  const handleSolutionOne = (e, type) => {
			if (type === "label") {
			  let d = currentEnvOption.filter((v, i) => {
				return v?.id === parseInt(e.target.value);
			  });
		  
			  if (e.target.value === "") {
				removeItemById(0);
			  } else {
				addObjectToChartArray({
				  value: d[0]?.value || 0, // Fallback if value is undefined
				  maxVal: 10,
				  label: "Productivity Applications",
				  id: 0,
				});
			  }
		  
			  if (d[0]?.solutionName === "Other") {
				setIsDisableOneCurrent(false);
			  } else {
				setIsDisableOneCurrent(true);
			  }
		  
			  if (d[0]?.solutionName === "None") {
				setSelectNone((prevState) => ({
				  ...prevState,
				  one: true,
				}));
			  } else {
				setSelectNone((prevState) => ({
				  ...prevState,
				  one: false,
				}));
			  }
		  
			  let amount = d[0]?.amount || 0; // Fallback to 0 if amount is undefined
			  setSolutionOneValueMonthly(e.target.value ? amount : 0);
			  setSolutionOneValueYearly(currencyFormat(e.target.value ? amount * noOfUsers : 0));
			  setSolutionOneText(d.length ? d[0]?.solutionName : "");
		  
			  let dt1 = [
				"Productivity Applications",
				d.length ? d[0]?.solutionName : "",
				`$ ${currencyFormat(e.target.value ? amount * noOfUsers : 0)}`
			  ];
			  filterFunc(dt1);
			}
		  
			if (type === "value") {
			  let value = e.target.value ? parseFloat(e.target.value) : 0; // Ensure valid parsing
			  setSolutionOneValueMonthly(value);
			  setSolutionOneValueYearly(value);
		  
			  let dt1 = [
				"Productivity Applications",
				solutionOneText,
				`$ ${currencyFormat(value)}`
			  ];
			  filterFunc(dt1);
			}
		  };
		  
		  const handleSolutionTwo = (e, type) => {
			if (type === "label") {
			  let d = currentEnvOption.filter((v, i) => {
				return v?.id === parseInt(e.target.value);
			  });
		  
			  if (e.target.value === "") {
				removeItemById(1);
			  } else {
				addObjectToChartArray({
				  value: d[0]?.value || 0, // Fallback to 0 if value is undefined
				  maxVal: 8,
				  label: "Multi-Factor Authentication (MFA/2FA)",
				  id: 1,
				});
			  }
		  
			  if (d[0]?.solutionName === "Other") {
				setIsDisableTwoCurrent(false);
			  } else {
				setIsDisableTwoCurrent(true);
			  }
		  
			  if (d[0]?.solutionName === "None") {
				setSelectNone((prevState) => ({
				  ...prevState,
				  two: true,
				}));
			  } else {
				setSelectNone((prevState) => ({
				  ...prevState,
				  two: false,
				}));
			  }
		  
			  let amount = d[0]?.amount || 0; // Fallback to 0 if amount is undefined
			  setSolutionTwoValueMonthly(e.target.value ? amount : 0);
			  setSolutionTwoValueYearly(currencyFormat(e.target.value ? amount * noOfUsers : 0));
			  setSolutionTwoText(d.length ? d[0]?.solutionName : "");
		  
			  let dt2 = [
				"Multi-Factor Authentication (MFA/2FA)",
				d.length ? d[0]?.solutionName : "",
				`$ ${currencyFormat(e.target.value ? amount * noOfUsers : 0)}`
			  ];
		  
			  filterFunc(dt2);
			}
		  
			if (type === "value") {
			  let value = e.target.value ? parseFloat(e.target.value) : 0; // Ensure valid parsing
			  setSolutionTwoValueMonthly(value);
			  setSolutionTwoValueYearly(value);
		  
			  let dt2 = [
				"Multi-Factor Authentication (MFA/2FA)",
				solutionTwoText,
				`$ ${currencyFormat(value)}`
			  ];
		  
			  filterFunc(dt2);
			}
		  };
		  
		  const handleSolutionThree = (e, type) => {
			if (type === "label") {
			  let d = currentEnvOption.filter((v) => v?.id === parseInt(e.target.value));
		  
			  if (e.target.value === "") {
				removeItemById(2);
			  } else {
				addObjectToChartArray({
				  value: d[0]?.value || 0, // Ensure fallback if `value` is undefined
				  maxVal: 9,
				  label: "Single Sign-On (SSO)",
				  id: 2,
				});
			  }
		  
			  if (d[0]?.solutionName === "Other") {
				setIsDisableThreeCurrent(false);
			  } else {
				setIsDisableThreeCurrent(true);
			  }
		  
			  setSelectNone((prevState) => ({
				...prevState,
				three: d[0]?.solutionName === "None",
			  }));
		  
			  let amount = d[0]?.amount || 0; // Fallback to 0 if amount is undefined
			  setSolutionThreeValueMonthly(e.target.value ? amount : 0);
			  setSolutionThreeValueYearly(currencyFormat(e.target.value ? amount * noOfUsers : 0));
			  setSolutionThreeText(d.length ? d[0]?.solutionName : "");
		  
			  let dt3 = [
				"Single Sign-On (SSO)",
				d.length ? d[0]?.solutionName : "",
				`$ ${currencyFormat(e.target.value ? amount * noOfUsers : 0)}`,
			  ];
			  filterFunc(dt3);
			}
		  
			if (type === "value") {
			  let value = e.target.value ? parseFloat(e.target.value) : 0; // Ensure `parseFloat` operates on a valid string
			  setSolutionThreeValueMonthly(value);
			  setSolutionThreeValueYearly(value);
		  
			  let dt3 = [
				"Single Sign-On (SSO)",
				solutionThreeText,
				`$ ${currencyFormat(value)}`,
			  ];
			  filterFunc(dt3);
			}
		  };
		  
		  const handleSolutionFour = (e, type) => {
			if (type === "label") {
			  let d = currentEnvOption.filter((v) => v?.id === parseInt(e.target.value));
		  
			  if (e.target.value === "") {
				removeItemById(4);
			  } else {
				addObjectToChartArray({
				  value: d[0]?.value || 0, // Ensure fallback if `value` is undefined
				  maxVal: 8,
				  label: "Cloud Storage",
				  id: 4,
				});
			  }
		  
			  if (d[0]?.solutionName === "Other") {
				setIsDisableFourCurrent(false);
			  } else {
				setIsDisableFourCurrent(true);
			  }
		  
			  setSelectNone((prevState) => ({
				...prevState,
				four: d[0]?.solutionName === "None",
			  }));
		  
			  let amount = d[0]?.amount || 0; // Fallback to 0 if amount is undefined
			  setSolutionFourValueMonthly(e.target.value ? amount : 0);
			  setSolutionFourValueYearly(currencyFormat(e.target.value ? amount * noOfUsers : 0));
			  setSolutionFourText(d.length ? d[0]?.solutionName : "");
		  
			  let dt4 = [
				"Cloud Storage",
				d.length ? d[0]?.solutionName : "",
				`$ ${currencyFormat(e.target.value ? amount * noOfUsers : 0)}`,
			  ];
			  filterFunc(dt4);
			}
		  
			if (type === "value") {
			  let value = e.target.value ? parseFloat(e.target.value) : 0; // Ensure `parseFloat` operates on a valid string
			  setSolutionFourValueMonthly(value);
			  setSolutionFourValueYearly(value);
		  
			  let dt4 = [
				"Cloud Storage",
				solutionFourText,
				`$ ${currencyFormat(value)}`,
			  ];
			  filterFunc(dt4);
			}
		  };
		  
	 
		  const handleSolutionFive = (e, type) => {
			if (type === "label") {
			  let d = currentEnvOption.filter((v) => v?.id === parseInt(e.target.value));
		  
			  if (e.target.value === "") {
				removeItemById(5);
			  } else {
				addObjectToChartArray({
				  value: d[0]?.value || 0,
				  maxVal: 9,
				  label: "Chat",
				  id: 5
				});
			  }
		  
			  if (d[0]?.solutionName === "Other") {
				setIsDisableFiveCurrent(false);
			  } else {
				setIsDisableFiveCurrent(true);
			  }
		  
			  if (d[0]?.solutionName === "None") {
				setSelectNone((prevState) => ({
				  ...prevState,
				  five: true,
				}));
			  } else {
				setSelectNone((prevState) => ({
				  ...prevState,
				  five: false,
				}));
			  }
		  
			  let amount = d[0]?.amount || 0; // Fallback to 0 if amount is undefined
			  setSolutionFiveValueMonthly(e.target.value ? amount : 0);
			  setSolutionFiveValueYearly(currencyFormat(e.target.value ? amount * noOfUsers : 0));
			  setSolutionFiveText(d.length ? d[0]?.solutionName : "");
		  
			  let dt5 = [
				"Chat",
				d.length ? d[0]?.solutionName : "",
				`$ ${currencyFormat(e.target.value ? amount * noOfUsers : 0)}`
			  ];
		  
			  filterFunc(dt5);
			}
		  
			if (type === "value") {
			  let value = e.target.value ? parseFloat(e.target.value) : 0; // Ensure `parseFloat` operates on a valid string
			  setSolutionFiveValueMonthly(value);
			  setSolutionFiveValueYearly(value);
		  
			  let dt5 = [
				"Chat",
				solutionFiveText,
				`$ ${currencyFormat(value)}`
			  ];
		  
			  filterFunc(dt5);
			}
		  };
		  
	const handleSolutionEleven = (e, type) => {
		if (type === "label") {
		  let d = currentEnvOption.filter((v, i) => {
			return v?.id === parseInt(e.target.value);
		  });
	  
		  if (e.target.value === "") {
			removeItemById(6);
		  } else {
			addObjectToChartArray({
			  value: d[0]?.value || 0,  // Ensure fallback if `value` is undefined
			  maxVal: 9,
			  label: "Video Conference",
			  id: 6
			});
		  }
	  
		  if (d[0]?.solutionName === "Other") {
			setIsDisableElevenCurrent(false);
		  } else {
			setIsDisableElevenCurrent(true);
		  }
	  
		  if (d[0]?.solutionName === "None") {
			setSelectNone((prevState) => ({
			  ...prevState,
			  eleven: true,
			}));
		  } else {
			setSelectNone((prevState) => ({
			  ...prevState,
			  eleven: false,
			}));
		  }
	  
		  let amount = d[0]?.amount || 0; // Fallback to 0 if amount is undefined
		  setSolutionElevenValueMonthly(e.target.value ? amount : 0);
		  setSolutionElevenValueYearly(currencyFormat(e.target.value ? amount * noOfUsers : 0));
		  setSolutionElevenText(d.length ? d[0]?.solutionName : "");
	  
		  let dt11 = [
			"Video Conference",
			d.length ? d[0]?.solutionName : "",
			`$ ${currencyFormat(e.target.value ? amount * noOfUsers : 0)}`
		  ];
		 	  
		  filterFunc(dt11);
		}
	  
		if (type === "value") {
		  let value = e.target.value ? parseFloat(e.target.value) : 0; // Ensure `parseFloat` operates on a valid string
		  setSolutionElevenValueMonthly(value);
		  setSolutionElevenValueYearly(value);
	  
		  let dt11 = [
			"Video Conference",
			solutionElevenText,
			`$ ${currencyFormat(value)}`
		  ];
	  
		  filterFunc(dt11);
		}
	  };
	  
	  const handleSolutionTwelve = (e, type) => {
		if (type === "label") {
		  let d = currentEnvOption.filter((v) => v?.id === parseInt(e.target.value));
	  
		  if (e.target.value === "") {
			removeItemById(12);
		  } else {
			addObjectToChartArray({
			  value: d[0]?.value || 0,
			  maxVal: 9,
			  label: "Backup",
			  id: 12
			});
		  }
	  
		  if (d[0]?.solutionName === "Other") {
			setIsDisableTwelveCurrent(false);
		  } else {
			setIsDisableTwelveCurrent(true);
		  }
	  
		  if (d[0]?.solutionName === "None") {
			setSelectNone((prevState) => ({
			  ...prevState,
			  twelve: true,
			}));
		  } else {
			setSelectNone((prevState) => ({
			  ...prevState,
			  twelve: false,
			}));
		  }
	  
		  let amount = d[0]?.amount || 0; // Fallback to 0 if amount is undefined
		  setSolutionTwelveValueMonthly(e.target.value ? amount : 0);
		  setSolutionTwelveValueYearly(currencyFormat(e.target.value ? amount * noOfUsers : 0));
		  setSolutionTwelveText(d.length ? d[0]?.solutionName : "");
	  
		  let dt12 = [
			"Backup",
			d.length ? d[0]?.solutionName : "",
			`$ ${currencyFormat(e.target.value ? amount * noOfUsers : 0)}`
		  ];
	  
		  filterFunc(dt12);
		}
	  
		if (type === "value") {
		  let value = e.target.value ? parseFloat(e.target.value) : 0; // Ensure `parseFloat` operates on a valid string
		  setSolutionTwelveValueMonthly(value);
		  setSolutionTwelveValueYearly(value);
	  
		  let dt12 = [
			"Backup",
			solutionTwelveText,
			`$ ${currencyFormat(value)}`
		  ];
	  
		  filterFunc(dt12);
		}
	  };
	  
	  const handleSolutionSix = (e, type) => {
		if (type === "label") {
		  let d = currentEnvOption.filter((v) => v?.id === parseInt(e.target.value));
	  
		  if (e.target.value === "") {
			removeItemById(7);
		  } else {
			addObjectToChartArray({
			  value: d[0]?.value || 0, // Ensure fallback if `value` is undefined
			  maxVal: 8,
			  label: "Email Threat Protection",
			  id: 7
			});
		  }
	  
		  if (d[0]?.solutionName === "Other") {
			setIsDisableSixCurrent(false);
		  } else {
			setIsDisableSixCurrent(true);
		  }
	  
		  if (d[0]?.solutionName === "None") {
			setSelectNone((prevState) => ({
			  ...prevState,
			  six: true,
			}));
		  } else {
			setSelectNone((prevState) => ({
			  ...prevState,
			  six: false,
			}));
		  }
	  
		  let amount = d[0]?.amount || 0; // Fallback to 0 if amount is undefined
		  setSolutionSixValueMonthly(e.target.value ? amount : 0);
		  setSolutionSixValueYearly(currencyFormat(e.target.value ? amount * noOfUsers : 0));
		  setSolutionSixText(d.length ? d[0]?.solutionName : "");
	  
		  let dt6 = [
			"Email Threat Protection",
			d.length ? d[0]?.solutionName : "",
			`$ ${currencyFormat(e.target.value ? amount * noOfUsers : 0)}`
		  ];
	  
		  filterFunc(dt6);
		}
	  
		if (type === "value") {
		  let value = e.target.value ? parseFloat(e.target.value) : 0; // Ensure `parseFloat` operates on a valid string
		  setSolutionSixValueMonthly(value);
		  setSolutionSixValueYearly(value);
	  
		  let dt6 = [
			"Email Threat Protection",
			solutionSixText,
			`$ ${currencyFormat(value)}`
		  ];
	  
		  filterFunc(dt6);
		}
	  };
	  
	const handleSolutionSeven = (e, type) => {
		if(type === "label"){
			let d = currentEnvOption.filter((v, i) => {
				return v?.id === parseInt(e.target.value)
			})
			if(e.target.value==""){
				removeItemById(8)
			  }else{
				addObjectToChartArray({ value: d[0]?.value || 0,maxVal:9,label:"Endpoint...",id:8})
			}
			if(d[0]?.solutionName == "Other"){
				setIsDisableSevenCurrent(false)
			}else{
				setIsDisableSevenCurrent(true)
			}
			if (d[0]?.solutionName === "None") {
				setSelectNone((prevState) => ({
				  ...prevState,
				  seven: true,
				}));
			  } else {
				setSelectNone((prevState) => ({
				  ...prevState,
				  seven: false,
				}));
			  }
			setSolutionSevenValueMonthly((e.target.value?d[0]?.amount:0))
			setSolutionSevenValueYearly(currencyFormat(e.target.value?d[0]?.amount*noOfUsers:0))
			setSolutionSevenText(d.length?d[0]?.solutionName:"")
			let dt7=["Endpoint Antivirus/AntiMalware (EDR/XDR)",d.length?d[0]?.solutionName:"","$ "+currencyFormat(e.target.value?d[0]?.amount*noOfUsers:0)];
			filterFunc(dt7);

		}
		if(type === "value"){
			setSolutionSevenValueMonthly(e.target.value)
			setSolutionSevenValueYearly(e.target.value)
			let dt7=["Endpoint Antivirus/AntiMalware (EDR/XDR)",solutionSevenText,"$ "+currencyFormat(e.target.value?parseFloat(e.target.value):0)];
			filterFunc(dt7);
		}
	}
	const handleSolutionEight = (e, type) => {
		if (type === "label") {
			let d = currentEnvOption.filter((v) => v?.id === parseInt(e.target.value));
	
			if (e.target.value === "") {
				removeItemById(9);
			} else {
				addObjectToChartArray({
					value: d[0]?.value || 0, // Ensure fallback if `value` is undefined
					maxVal: 9,
					label: "Endpoint Management (MDM)",
					id: 9
				});
			}
	
			if (d[0]?.solutionName === "Other") {
				setIsDisableEightCurrent(false);
			} else {
				setIsDisableEightCurrent(true);
			}
	
			if (d[0]?.solutionName === "None") {
				setSelectNone((prevState) => ({
					...prevState,
					eight: true,
				}));
			} else {
				setSelectNone((prevState) => ({
					...prevState,
					eight: false,
				}));
			}
	
			let amount = d[0]?.amount || 0; // Fallback to 0 if amount is undefined
			setSolutionEightValueMonthly(e.target.value ? amount : 0);
			setSolutionEightValueYearly(currencyFormat(e.target.value ? amount * noOfUsers : 0));
			setSolutionEightText(d.length ? d[0]?.solutionName : "");
	
			let dt8 = [
				"Endpoint Management (MDM)",
				d.length ? d[0]?.solutionName : "",
				`$ ${currencyFormat(e.target.value ? amount * noOfUsers : 0)}`
			];
	
			filterFunc(dt8);
		}
	
		if (type === "value") {
			let value = e.target.value ? parseFloat(e.target.value) : 0; // Ensure `parseFloat` operates on a valid string
			setSolutionEightValueMonthly(value);
			setSolutionEightValueYearly(value);
	
			let dt8 = [
				"Endpoint Management (MDM)",
				solutionEightText,
				`$ ${currencyFormat(value)}`
			];
	
			filterFunc(dt8);
		}
	};
	
	const handleSolutionNine = (e, type) => {
		if (type === "label") {
			let d = currentEnvOption.filter((v) => v?.id === parseInt(e.target.value));
	
			if (e.target.value === "") {
				removeItemById(10);
			} else {
				addObjectToChartArray({
					value: d[0]?.value || 0, // Ensure fallback if `value` is undefined
					maxVal: 8,
					label: "24x7 Security Operations Center (SOC) + MDR",
					id: 10
				});
			}
	
			if (d[0]?.solutionName === "Other") {
				setIsDisableNineCurrent(false);
			} else {
				setIsDisableNineCurrent(true);
			}
	
			if (d[0]?.solutionName === "None") {
				setSelectNone((prevState) => ({
					...prevState,
					nine: true,
				}));
			} else {
				setSelectNone((prevState) => ({
					...prevState,
					nine: false,
				}));
			}
	
			let amount = d[0]?.amount || 0; // Fallback to 0 if amount is undefined
			setSolutionNineValueMonthly(e.target.value ? amount : 0);
			setSolutionNineValueYearly(currencyFormat(e.target.value ? amount * noOfUsers : 0));
			setSolutionNineText(d.length ? d[0]?.solutionName : "");
	
			let dt9 = [
				"24x7 Security Operations Center (SOC) + MDR",
				d.length ? d[0]?.solutionName : "",
				`$ ${currencyFormat(e.target.value ? amount * noOfUsers : 0)}`
			];
	
			filterFunc(dt9);
		}
	
		if (type === "value") {
			let value = e.target.value ? parseFloat(e.target.value) : 0; // Ensure `parseFloat` operates on a valid string
			setSolutionNineValueMonthly(value);
			setSolutionNineValueYearly(value);
	
			let dt9 = [
				"24x7 Security Operations Center (SOC) + MDR",
				solutionNineText,
				`$ ${currencyFormat(value)}`
			];
	
			filterFunc(dt9);
		}
	};
	
	const handleSolutionTen = (e, type) => {
		if (type === "label") {
			let d = currentEnvOption.filter((v) => v?.id === parseInt(e.target.value));
	
			if (e.target.value === "") {
				removeItemById(11);
			} else {
				addObjectToChartArray({
					value: d[0]?.value || 0, // Ensure fallback if `value` is undefined
					maxVal: 2,
					label: "Additional",
					id: 11
				});
			}
	
			if (d[0]?.solutionName === "Other") {
				setIsDisableTenCurrent(false);
			} else {
				setIsDisableTenCurrent(true);
			}
	
			let amount = d[0]?.amount || 0; // Fallback to 0 if amount is undefined
			setSolutionTenValueMonthly(e.target.value ? amount : 0);
			setSolutionTenValueYearly(currencyFormat(e.target.value ? amount * noOfUsers : 0));
			setSolutionTenText(d.length ? d[0]?.solutionName : "");
	
			let dt10 = [
				"Additional",
				d.length ? d[0]?.solutionName : "",
				`$ ${currencyFormat(e.target.value ? amount * noOfUsers : 0)}`
			];
	
			filterFunc(dt10);
		}
	
		if (type === "value") {
			let value = e.target.value ? parseFloat(e.target.value) : 0; // Ensure `parseFloat` operates on a valid string
			setSolutionTenValueMonthly(value);
			setSolutionTenValueYearly(currencyFormat(value));
	
			let dt10 = [
				"Additional",
				solutionTenText,
				`$ ${currencyFormat(value)}`
			];
	
			filterFunc(dt10);
		}
	};
	

	const handleAltSolutionOne = (e, type) => {
		if(type === "label"){
			
			let d = updatedEnvOption.filter((v, i) => {
				return v?.id === parseInt(e.target.value)
			})
			setAltSolutionOneText(d.length?d[0]?.solutionName:"")
			setTextAreaBlock(d[0]?.description)
			setAltSolutionOneValueMonthly((e.target.value?d[0]?.amount:0))
			setAltSolutionOneValueYearly(currencyFormat(e.target.value?d[0]?.amount*noOfUsers:0))
           
			let index = e.nativeEvent.target.selectedIndex;
			let Sollabel = e.nativeEvent.target[index].text;
			let dt10=["Updated Environment", Sollabel,"$ "+currencyFormat(e.target.value?d[0]?.amount*noOfUsers:0)];
             filterFuncAlt(dt10);
		}
	}
	
	const handleBackup = (e) => {
		setBackupPerUser(e.target.value?e.target.value:0)
		setBackup(currencyFormat(e.target.value?parseFloat(e.target.value*noOfUsers):0))
		let dtuu = "";
		if(e.target.value != "0"){
			dtuu = ["Backup (365 Tenant)", "Backup (365 Tenant) - Acronis","$ "+currencyFormat(e.target.value?parseFloat(e.target.value*noOfUsers):0)];
		}else{
			dtuu = ["Backup (365 Tenant)", "Backup (365 Tenant) - None","$ "+currencyFormat(0)];
		}
		filterFuncAlt(dtuu);
	}
	const handleSOC = (e) => {
		setSocPerUser(e.target.value?e.target.value:0)
		setSoc(currencyFormat(e.target.value?parseFloat(e.target.value*noOfUsers):0))
		let dtuu = "";
		if(e.target.value != "0"){
			dtuu = ["24x7 Security Operations Center (SOC) + MDR", "24x7 Security Operations Center (SOC) + MDR - Blackpoint Cyber","$ "+currencyFormat(e.target.value?parseFloat(e.target.value*noOfUsers):0)];
		}else{
			dtuu = ["24x7 Security Operations Center (SOC) + MDR", "MDR(Managed Detection Response ) - None","$ "+currencyFormat(0)];
		}
		///console.log("handleSOC",dtuu);
		filterFuncAlt(dtuu);
	}
//calculte total
	React.useEffect(() => { 
		if(solutionOneValueYearly || 
		solutionTwoValueYearly || 
		solutionThreeValueYearly || 
		solutionFourValueYearly || 
		solutionFiveValueYearly || 
		solutionElevenValueYearly ||
		solutionTwelveValueYearly ||
		solutionSixValueYearly || 
		solutionSevenValueYearly || 
		solutionEightValueYearly || 
		solutionNineValueYearly || solutionTenValueYearly){
			let calculateValue = parseFloat(solutionOneValueYearly?currencyUnformat(solutionOneValueYearly):0)+parseFloat(solutionTwoValueYearly?currencyUnformat(solutionTwoValueYearly):0)+parseFloat(solutionThreeValueYearly?currencyUnformat(solutionThreeValueYearly):0)+parseFloat(solutionFourValueYearly?currencyUnformat(solutionFourValueYearly):0)+parseFloat(solutionFiveValueYearly?currencyUnformat(solutionFiveValueYearly):0)+parseFloat(solutionElevenValueYearly?currencyUnformat(solutionElevenValueYearly):0)+parseFloat(solutionSixValueYearly?currencyUnformat(solutionSixValueYearly):0)+parseFloat(solutionSevenValueYearly?currencyUnformat(solutionSevenValueYearly):0)+parseFloat(solutionEightValueYearly?currencyUnformat(solutionEightValueYearly):0)+parseFloat(solutionNineValueYearly?currencyUnformat(solutionNineValueYearly):0)+parseFloat(solutionTenValueYearly?currencyUnformat(solutionTenValueYearly):0)+parseFloat(solutionTwelveValueYearly?currencyUnformat(solutionTwelveValueYearly):0);
			/*setTotalMonthlyCost(currencyFormat(calculateValue))
			setTotalYearlyCost(currencyFormat(calculateValue))*/
			/*setTotalUsersMonthlyCost(currencyFormat(calculateValue*noOfUsers))
			setTotalUsersYearlyCost(currencyFormat(calculateValue*noOfUsers))*/
			setTotalUsersMonthlyCost(currencyFormat(calculateValue))
			setTotalUsersYearlyCost(currencyFormat(calculateValue))
		}else{
			setTotalMonthlyCost(currencyFormat(0))
			setTotalYearlyCost(currencyFormat(0))
			setTotalUsersMonthlyCost(currencyFormat(0))
			setTotalUsersYearlyCost(currencyFormat(0))
		}
		
	},[solutionOneValueYearly,
		solutionTwoValueYearly,
		solutionThreeValueYearly,
		solutionFourValueYearly,
		solutionFiveValueYearly,
		solutionElevenValueYearly, 
		solutionTwelveValueYearly,
		solutionSixValueYearly,
		solutionSevenValueYearly,
		solutionEightValueYearly,
		solutionNineValueYearly, noOfUsers, solutionTenValueYearly])



	React.useEffect(() => {
		if(altSolutionOneValueYearly || backup || soc){
			let altCalculateValue = parseFloat(altSolutionOneValueYearly?currencyUnformat(altSolutionOneValueYearly):0)+parseFloat(backup?backup.replace(/,/g, ''):0)+parseFloat(soc?soc.replace(/,/g, ''):0)
			/*setAltTotalMonthlyCost(currencyFormat(altCalculateValue))
			setAltTotalYearlyCost(currencyFormat(altCalculateValue))
			setAltTotalUsersMonthlyCost(currencyFormat(altCalculateValue*noOfUsers))
			setAltTotalUsersYearlyCost(currencyFormat(altCalculateValue*noOfUsers))*/
			setAltTotalUsersMonthlyCost(currencyFormat(altCalculateValue))
			setAltTotalUsersYearlyCost(currencyFormat(altCalculateValue))
		}else{
			/*setAltTotalMonthlyCost(currencyFormat(0))
			setAltTotalYearlyCost(currencyFormat(0))*/
			setAltTotalUsersMonthlyCost(currencyFormat(0))
			setAltTotalUsersYearlyCost(currencyFormat(0))
		}
		
	},[altSolutionOneValueYearly, noOfUsers, backup, soc])
	React.useEffect(() => {
		let roiSaving = parseFloat(totalUsersMonthlyCost?totalUsersMonthlyCost.replace(/,/g, ''):0) - parseFloat(altTotalUsersMonthlyCost?altTotalUsersMonthlyCost.replace(/,/g, ''):0)
		setMonthlySaving(roiSaving)
		setYearlySaving(roiSaving)
	},[totalUsersMonthlyCost, altTotalUsersMonthlyCost])
	const handleCLP = (e) => {
		//setCyberPremium(e.target.value)
		setCyberPremium(e.target.value)
		if(!e.target.value){
			setApiPolicyDis(5)
			setLowRiskPremium(lowRiskPremium)
		}
	}
	const handleApiPolicyDis = (e) => {
		setApiPolicyDis(e.target.value)
	}
	const handleLowRisk = (e) => {
		setLowRiskPremium(e.target.value);
		setLowRiskError(false)
		let index = e.nativeEvent.target.selectedIndex;
		let label = e.nativeEvent.target[index].text;
		setELRP(label);
	}
	
  /* Implementing API */
 
	React.useEffect(() => {
		getQuoteNumber();
		getAEName();
		updatedEnvironmentSolution();
		currentEnvironmentSolution();
	},[])
    
	const getQuoteNumber = async()=>{
			await axios.get(API_URL+"get-latest-quote-number").then((res)=>{
				if(res?.data?.status==200){
					let currentDate = new Date();
					let currentYear = currentDate.getFullYear();
					let qN =res?.data?.Rdata[0].initialNumber;
					let quoatNum ="Cyber-"+ (qN<10?"00"+qN:(qN>=10 && qN<99?"0"+qN:qN) )+"-"+ currentYear ;
					setQuoteNumber(quoatNum);		
				}
			}).catch((e) => {
				console.log(e)
	        })
	}

	const getAEName = async()=>{
		await axios.get(API_URL+"ae-name").then((res)=>{
			if(res?.data?.status==200){
				setAeOption(res.data.Rdata);	
			}
		}).catch((e) => {
				console.log(e)
	        })	
	}

	const updatedEnvironmentSolution = async()=>{
		await axios.get(API_URL+"updated-environment-solution").then((res)=>{
			if(res?.data?.status==200){
				setUpdatedEnvOption(res.data.Rdata);	
			}
		}).catch((e) => {
				console.log(e)
	        })
	}

	const currentEnvironmentSolution = async()=>{
		await axios.get(API_URL+"current-environment-solution").then((res)=>{
			if(res?.data?.status==200){
				setCurrentEnvOption(res.data.Rdata);	
			}
		}).catch((e) => {
				console.log(e)
	        })	
	}

	React.useEffect(() => {
		let apiPolicyVal = cyberPremium * (apiPolicyDis/100)
		setApiPolicyDisValue(apiPolicyVal)
		let lowRiskPremiumVal = cyberPremium * (lowRiskPremium/100)
		setLowRiskPremiumVal(lowRiskPremiumVal)
		setAnnualROI(apiPolicyVal+lowRiskPremiumVal)
	},[cyberPremium, apiPolicyDis, lowRiskPremium])


    
  const filterFunc = (data)=>{		
	let u = dataOfCEForPdf?.map( (x,j) => {
		 let t=  x.map((y,i) => {
			    if(y == data[0]){
						  return i;
				}
			 })
		  if(t[0]==0){
			 return j
		  }
	 })

	 let m = u.filter(x =>  x!=undefined)

      if(m.length){
		dataOfCEForPdf.map( (x,j)=>{
			if(x[0]==data[0]){
				x[1]=data[1];
				x[2]=data[2];
				/*x[3]=data[3];*/
			}		
			})
	  }else{
		
		//setDataOfCEForPdf(dataOfCEForPdf => [...dataOfCEForPdf , data])
		const desiredOrder = ["Productivity Applications", "Multi-Factor Authentication (MFA/2FA)", "Single Sign-On (SSO)","Cloud Storage","Chat",
			"Video Conference","Email Threat Protection","Endpoint Antivirus/AntiMalware (EDR/XDR)","Endpoint Management (MDM)",
			"24x7 Security Operations Center (SOC) + MDR","Backup","Additional"
		];

		setDataOfCEForPdf(dataOfCEForPdf => {
		   
			const updatedData = [...dataOfCEForPdf, data];
			
		   
			updatedData.sort((a, b) => {
				return desiredOrder.indexOf(a[0]) - desiredOrder.indexOf(b[0]);
			});
			
		   
			return updatedData;
		});
		
	  }

  }


  //console.log(updatedEnvOption,"435673456473345");
  

  const filterFuncAlt = (data)=>{
	let u = dataOfUEForPdf?.map( (x,j) => {
		 let t=  x.map((y,i) => {
			    if(y == data[0]){
						  return i;
				}
			 })
		  if(t[0]==0){
			 return j
		  }
	 })

	 let m = u.filter(x =>  x!=undefined)

      if(m.length){
		dataOfUEForPdf.map( (x,j)=>{
			if(x[0]==data[0]){
				x[1]=data[1];
				x[2]=data[2];
				/*x[3]=data[3];*/
			}		
			})
	  }else{
        
     		//setDataOfUEForPdf(dataOfUEForPdf => [...dataOfUEForPdf , data])

		
		const desiredOrder = ["Updated Environment", "24x7 Security Operations Center (SOC) + MDR", "Backup (365 Tenant)"];

			setDataOfUEForPdf(dataOfUEForPdf => {
			   
			    const updatedData = [...dataOfUEForPdf, data];
			    
			   
			    updatedData.sort((a, b) => {
			        return desiredOrder.indexOf(a[0]) - desiredOrder.indexOf(b[0]);
			    });
			    
			   
			    return updatedData;
			});

	  }

  }
 	/*React.useEffect(() => {
 		if(noOfUsers > 0){
 			setSolutionOneValueYearly(solutionOneValueYearly*noOfUsers)
 		}
 	},[noOfUsers])*/
/*  React.useEffect(() => {
  	console.log(dataOfUEForPdf)
  },[dataOfUEForPdf])*/
  const [notes, setNotes] = React.useState("")
  const handleNotes = (e) => {
  	setNotes(e.target.value)
  }
  const validateEmail = (email) => {
	  return email.match(
	    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	  );
	};
  const handleValidation = () => {
  	let isFormValid = true;
  	if(!companyName){
  		setCompanyNameError(true)
  		isFormValid = false;
  	}
  	if(covenantAEName == "1" || !covenantAEName){
  		setCovenantAENameError(true)
  		isFormValid = false;
  	}
  	if(!createdDate){
  		setCreatedDateError(true)
  		isFormValid = false;
  	}
  	if(!startDate){
  		setStartDateError(true)
  		isFormValid = false;
  	}
  	if(!customerName){
  		setCustomerNameError(true)
  		isFormValid = false;
  	}
  	if(!customerTitle){
  		setCustomerTitleError(true)
  		isFormValid = false;
  	}
  	if(!customerContact){
  		setCustomerContactError(true)
  		isFormValid = false;
  	}
  	if(!validateEmail(customerContact)){
		setCustomerContactError(true)
		isFormValid = false;
	}
  	if(!technicalName){
  		setTechnicalNameError(true)
  		isFormValid = false;
  	}
  	if(!technicalContact){
  		setTechnicalContactError(true)
  		isFormValid = false;
  	}
  	if(!validateEmail(technicalContact)){
		setTechnicalContactError(true)
  		isFormValid = false;
	}
	if(!lowRiskPremium){
		setLowRiskError(true)
  		isFormValid = false;
	}
  	return isFormValid;
  }
  const handleFloatNumber = (e) => {
		if ((e.which != 46 || e.target.value.indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
			e.preventDefault();
		}
	}

   let ptext= "Covenant Cyber Assure Program";
	const generatePDF = async () => {
		if(handleValidation()){
		let doc = new jsPDF('p','mm',[297, 210]);
		doc.addImage(LogoPDF, "PNG", 0, 0, 25, 10);
		doc.setFontSize(9);
		doc.text("Quote Number: ", 150, 10);
		doc.rect(175, 6.5, 30, 5);
		doc.text(quoteNumber, 177, 10);
		doc.setDrawColor(0);    
	    doc.setFillColor(255, 195, 0);    
	    //doc.rect(7, 13, 198, 3, 'FD');
	    doc.rect(7, 15, 198, 3, 'FD');
	    doc.setFont(undefined, 'bold').text("Company Name: ", 7, 24).setFont(undefined, 'normal');
		//doc.rect(34, 26.5, 30, 5);
		doc.text(companyName,  34, 24);
		//Add Company Name Data
		doc.setFont(undefined, 'bold').text("Covenant AE Name: ", 120, 24).setFont(undefined, 'normal');
		//doc.rect(175, 26.5, 30, 5); 
		doc.text(aeNameLabel, 152, 24);
		//Add Covenant AE Name Data
		doc.setFont(undefined, 'bold').text("Date Created: ", 129, 28).setFont(undefined, 'normal');
		//doc.rect(175, 33.5, 30, 5);
		if(createdDate){
			doc.text(moment(createdDate).format("MM-DD-YYYY"), 152, 28);
		}
		//doc.text(createdDate, 152, 37);
		//Add Date Created
		doc.setFillColor(255, 195, 0); 
		doc.rect(7, 30.5, 198, 1.5, 'FD');

		doc.setFont(undefined, 'bold').text("Customer Name: ", 7, 37).setFont(undefined, 'normal');
		//doc.rect(34, 43.5, 30, 5);
		doc.text(customerName, 34, 37);

		doc.setFont(undefined, 'bold').text("Technical Name: ", 125, 37).setFont(undefined, 'normal');
		//doc.rect(175, 43.5, 30, 5);
        doc.text(technicalName, 152, 37);

		doc.setFont(undefined, 'bold').text("Customer Title: ", 7, 41).setFont(undefined, 'normal');
		//doc.rect(34, 50.5, 30, 5);
		doc.text(customerTitle, 34, 41);

		doc.setFont(undefined, 'bold').text("Contact Email: ", 125, 41).setFont(undefined, 'normal');
		//doc.rect(175, 50.5, 30, 5);
		doc.text(technicalContact, 152, 41);

		doc.setFont(undefined, 'bold').text("Contact Email: ", 7, 45).setFont(undefined, 'normal');
		//doc.rect(34, 57.5, 30, 5);
        doc.text(customerContact, 34, 45);

		doc.setFont(undefined, 'bold').text("Start Date (Est.): ", 125, 45).setFont(undefined, 'normal');
		//doc.rect(175, 57.5, 30, 5);
		if(startDate){
			doc.text(moment(startDate).format("MM-DD-YYYY"), 152, 45);
		}
		

		doc.setFillColor(255, 195, 0); 
		doc.rect(7, 49.5, 198, 1.5, 'FD');
        
		doc.setFontSize(19,"bold");
		doc.text(ptext, 36, 61);
		doc.setFontSize(10);
		//doc.text("inspired by", 123, 61);
		doc.addImage(BlackLogo, "PNG", 138, 52, 40, 13);
	 //1st p
		doc.text("The", 10, 69);
		doc.setFont(undefined, 'bold').text("Covenant Cyber Assure program",17,69).setFont(undefined, 'normal');
		doc.text("was developed and made possible through collaborations between Covenant Global,",75,69);
		doc.text("Microsoft, and strategic partners. It is designed with data security in mind. We aim to provide a direct path to a secure Microsoft", 10, 73);
		doc.text(`365 environment for "higher risk" Covenant customers to reduce their cyber risk and cyber insurance costs.`, 10, 77);//1st p
		//2nd p 
	    doc.text("Covenant can help you achieve your security goals by migrating from your on-premises Exchange environment to Exchange", 10, 85);
		 doc.text("Online and cyber-hardening your Microsoft 365 ",10,89);
		//  doc.setFont(undefined, 'bold').text("Fortify™ ",85,89 ).setFont(undefined, 'normal')
		 doc.text("environment with our proprietary Fortify Identity (F1) & Device (F2) hardening", 84, 89);
		 doc.text("configurations. ",10,92)
		// doc.setFont(undefined, 'bold').text("Fortify™ ",78,92 ).setFont(undefined, 'normal')
		 doc.text("The even better news is that the Exchange migration and Fortify are fully funded through your participation ",35, 92);
		 doc.text("in the program, ensuring a cost-effective solution for your security needs!",10,96)
		 //3rd p
	 	doc.text("As an added value, we work closely with your team to review third-party digital assets, identify redundancies, and save", 10, 105);
		doc.text("additional costs by providing a consolidated, flexible approach to Microsoft licensing and cyber security management.", 10, 109);
	    
		doc.setFillColor(255, 208, 0);  
		doc.rect(7, 112, 211, 2, 'FD');
//takeAways
		doc.setFontSize(14);
		doc.setTextColor("#000000");
		doc.setFont(undefined, 'bold').text("Key Business Takeaways", 10, 130);
		doc.setFontSize(10);
		doc.setFont(undefined, 'normal');
		doc.text("1. Insurance Premium Reduction Opportunity", 10, 137);
		doc.text("2. Cost-Effective 365 Transition", 10, 142);
		doc.text("3. Complimentary Cloud Email Transition", 10, 147);
		doc.text("4. Enhanced Cyber Protection", 10, 152);
		doc.text("5. 24/7 Security Monitoring", 10, 157);
		  doc.addImage(graphicsDe, "PNG", 20,159, 180, 60); 
		  doc.addImage(graphicsDeS, "PNG", 20,225, 180, 60); 
		doc.setFontSize(14);
		doc.addPage();
		doc.text("Let’s compare!",90,6);
		
		doc.setFontSize(8);
        doc.text("No. of users ", 140, 5);
		doc.rect(157, 2, 30, 5);
		doc.text(noOfUsers, 160, 5);  

		
	    doc.setFontSize(7);		
		const headers = ["Current Environment", "Solution *", "Annual Cost"];
		const data =dataOfCEForPdf;

	// 	//console.log("trr",dataOfCEForPdf);

	// 	// Set the table starting position
		let x = 9;
		// Set the column widths
		const columnWidths = [45,35,17];

	// 	// Set the table styles
	  	const headerStyle = { fillColor: "#f3f5f5", textColor: "#292626" };
	  	const rowStyle = { fillColor: "#f2f2f2" };

		// Draw the table headers
		headers.forEach((header, index) => {
		doc.setFillColor(headerStyle.fillColor);
		doc.setTextColor(headerStyle.textColor);
		// doc.setFontStyle("bold");
		doc.rect(x, 10, columnWidths[index], 5, "F");
		doc.text(header, x + 2,13);
		x += columnWidths[index];
		});
	
     doc.setFontSize(6);

		x = 9;
		let  y = 15;
		let z = 15;
		let t = 15;
				
		data.forEach((row) => {
		row.forEach((cell, index) => {
			doc.setFillColor(headerStyle.fillColor);
			doc.setTextColor(0, 0, 0);
			doc.rect(x, y, columnWidths[index], 18, "F");

			let v =cell.length
			let k = 49; let vY=23;
			for(let i =0 ;i< Math.ceil(v/k) ;i++){	
				if(index === 0){
					doc.text(cell.slice(i*k, (i+1)*k), x + 2, t+3);
					t=t+2.5;
				}else if(index === 1){
					doc.text(cell.slice(i*k, (i+1)*k), x + 2, z+3);
					z=z+2.5;
				} else{
					doc.text(cell.slice(i*k, (i+1)*k), x + 2, y+3);
				}
				
			}

			//doc.text(cell, x + 2, y+3);
			x += columnWidths[index];
		});
		y=z+1;
		t = z+1;
		z=z+1;
		x = 9;
		});
		//monthly button  -- 16-09 (change from total monthly)
		doc.text("Total Annual", 90, 75); 
		doc.rect(88, 76, 18, 5);
		doc.text("$ "+totalUsersMonthlyCost,  91, 79);
		//chart js
		doc.setFontSize(14);
		doc.text("Security Posture",40,80);
		const canvas = chartRef.current?.canvas; 
		if (canvas) {
			const imgData = canvas.toDataURL('image/png'); 
			doc.addImage(imgData, 'PNG', 1, 83, 100, 43); 
		}
        /******Updated Environment********* */

		doc.setFontSize(7);
				
		const uEheaders = ["Updated Environment", "Annual Cost",];
		const uEdata =dataOfUEForPdf;
		let xx = 115;
		// Set the column widths
		const columnWidthss = [70,20];


		// Draw the table headers
		//console.log(uEheaders,"uyttcbbvvb");
		uEheaders.forEach((header, index) => {
		doc.setFillColor(headerStyle.fillColor);
		doc.setTextColor(headerStyle.textColor);
		doc.rect(xx, 10, columnWidthss[index], 5, "F");
		doc.text(header, xx + 2,13);
		xx += columnWidthss[index];
		});
		 
      doc.setFontSize(6);

		xx = 115;
		let  yy = 15;
		// Draw the table rows
		//console.log(uEdata)
		uEdata.forEach((row) => {
		row.forEach((cell, index) => {
			if(index > 0){
				doc.setFillColor(headerStyle.fillColor);
				doc.setTextColor(0, 0, 0);
				doc.rect(xx, yy, columnWidthss[index-1], 5, "F");
				doc.text(cell, xx + 2, yy+3);
				xx += columnWidthss[index-1];
			}
			
		});
		yy=yy+5;
		xx = 115;
		});		
		doc.setFillColor(headerStyle.fillColor);
		doc.setTextColor(headerStyle.textColor);
		doc.rect(115, 30, 90, 90, "F");

		 doc.rect(117, 30, 62, 89);
		// // //uuuuuuuuuu
        //  /************** */
		 let str = textAreaBlock?textAreaBlock.replace(/<\/?[^>]+(>|$)/g, ""):"";
		 let v =str.length
		//	console.log(v, Math.ceil(v/45))
		let k = 55; let vY=33;
		for(let i = 0 ;i< Math.ceil(v/k) ;i++){   	  
			doc.text(str.slice(i*k, (i+1)*k), 119, vY);
			vY=vY+2.5;
		}

		
        
		 doc.addImage(MSLogo, "PNG", 180, 89, 25, 10);
        
        /* left */
		// doc.rect(90, 124, 18, 5);
		//doc.text("$ "+totalMonthlyCost, 91, 127);

		//doc.rect(185, 124, 18, 5);
		//doc.text("$ "+totalYearlyCost, 185, 124);
        
	 	
	  
	//   doc.text("Total Monthly", 90, 123); 
    //      doc.rect(90, 124, 18, 5);
	// 	 doc.text("$ "+totalUsersMonthlyCost,  91, 127);
		 doc.text("Total Annual", 185, 123);
		 doc.rect(185, 124, 18, 5);
		 doc.text("$ "+totalUsersYearlyCost, 187, 127);

		  /* right */
		  /*doc.rect(164, 271, 18, 5);
		  doc.text("$ "+ altTotalMonthlyCost, 165, 274);
  
		  doc.rect(184, 271, 18, 5);
		  doc.text("$ " + altTotalYearlyCost, 185, 274);*/
		  
		  /*doc.text("Total Monthly", 164, 279);*/
		//   doc.text("Total Annual", 184, 283);
  
		  /*doc.rect(164, 280, 18, 5);
		  doc.text("$ " + altTotalUsersMonthlyCost, 165, 283);*/
  
		//   doc.rect(184, 284, 18, 5);
		//   doc.text("$ " + altTotalUsersYearlyCost, 185, 287);
	
		doc.setFontSize(14);
		doc.text("Time to see the ROI!",85,136);
        /** left */

		doc.setFontSize(10);
		doc.text("Cyber Policy ROI",42,140);

		doc.setFillColor(headerStyle.fillColor);
		doc.setTextColor(headerStyle.textColor);
		doc.rect(9, 143, 90, 40, "F");
         
		doc.setFontSize(6);
        doc.text("Cyber Liability Premium (Current)*" , 11,148);
		 doc.rect(63, 145, 34, 5);
         doc.text("$ "+currencyFormat(parseFloat(cyberPremium)?parseFloat(cyberPremium):0) , 64,148);
         //doc.text("$ "+cyberPremium , 64,148);

		 doc.text("API Policy Discount*" , 11,156);
		 doc.rect(63, 152, 34, 5);
         doc.text("% "+apiPolicyDis , 64,155);

		  doc.text("Est. Policy Discount*" , 11,161);
		 doc.text((apiPolicyDisValue?"- ":"")+currencyFormat(apiPolicyDisValue?apiPolicyDisValue:0) , 64,161);
		 doc.rect(63, 163, 34, 5);
		 doc.text(eLRP , 64,166);
		 doc.text((lowRiskPremiumVal?"-":"")+currencyFormat(lowRiskPremiumVal?lowRiskPremiumVal:0) , 64,171);

		doc.text("Annual Policy Savings (ROI)" , 11,180);
		doc.text(currencyFormat(annualROI?annualROI:0) , 64,180);
        
        doc.setFontSize(16);
		doc.text("+",103.5,162);

		 

		// /** right */
        doc.setFontSize(10);
		doc.text("Licensing ROI",142,140);
         
		// /** right part 1 */
		doc.setFontSize(6);
		doc.setFillColor(headerStyle.fillColor);
		doc.setTextColor(headerStyle.textColor);
		doc.rect(112, 143, 90, 12, "F");
         
		/*doc.text("Monthly Savings" , 114,23);
        doc.text(currencyFormat(monthlySaving, "$") , 154,23);*/

		doc.text("Annual Savings (ROI)" , 114,152);
        doc.text(currencyFormat(yearlySaving, "$") , 154,152);


        //  /** right part 2 */
        doc.setFontSize(10);
		doc.text("Projected ROI After Cyber Assure Completion",122,162);   
         
		 doc.setFontSize(6);   
		doc.setFillColor(headerStyle.fillColor);
		doc.setTextColor(headerStyle.textColor);
		doc.rect(112, 164, 90, 20, "F");

		doc.text("Exchange Migration Savings" , 114,168);
        doc.text(currencyFormat(eMSaving,"$") , 154,168);

		doc.text("Fortify Savings" , 114,172);
        doc.text(currencyFormat(fortifySaving,"$") , 154,172);

		doc.text("Cyber Assure Total Savings" , 114,176);
        doc.text(currencyFormat((parseFloat(eMSaving)+parseFloat(fortifySaving)),"$") , 154,176);
         doc.addImage(MSLogo, "PNG", 174, 169, 25, 10);
		doc.setFontSize(4.5);
		doc.text("**", 10, 164);
		doc.setFont(undefined, 'bold').text("Disclaimer", 11.4,164).setFont(undefined, 'normal');
		doc.text("** - ", 20, 164);
		doc.text("Cyber Premium savings are estimated and not",23,164);
		doc.text("guaranteed. There are several factors and variables that determine",10,165.5);
		doc.text("insurance \"risk\", Risk factors and premiums. Cyber Assure Program",10,167.5);
		doc.text("can provide and provide and updated premium upon completion of the",10,169.5);
		doc.text("Cyber Assure engagement. There are several factors and variables that",10,171.5); 
		doc.text("determine insurance \"risk\", Risk factors and premiums. Cyber Assure",10,173.5);
		doc.text("Program can provide and updated premium upon completion of the Cyber",10,175.5); 
		doc.text("Assure engagement.",10,177.5); 
		
		doc.setFontSize(13);
		doc.setFont(undefined, 'normal').text("Notes:",10,188).setFont(undefined, 'normal');
		doc.rect(10, 190, 191, 25);
		doc.setFontSize(8);
		let v_notes = notes.length;
		let k_notes = 135; 
		let x_notes = 11;
		let t_notes = 194;
		for(let i = 0 ;i< Math.ceil(v_notes/k_notes) ;i++){
			doc.text(notes.slice(i*k_notes, (i+1)*k_notes), x_notes, t_notes)
			t_notes=t_notes+3;
		}
		

		doc.setFontSize(14);
		doc.setFont(undefined, 'bold').text("Projected ROI After Cyber Assure Completion",63,228).setFont(undefined, 'normal');
				
		doc.setFontSize(10);   
		doc.setFillColor("#1976d2");
		doc.setTextColor("#ffffff");
		doc.rect(10, 235, 192, 20, "F");
        
		doc.setFont(undefined, 'bold').text("1 Year ROI", 20,246).setFont(undefined, 'normal');
		doc.setFont(undefined, 'bold').text(currencyFormat(parseFloat(yearlySaving)+parseFloat(annualROI)+parseFloat(eMSaving)+parseFloat(fortifySaving), "$"), 70,246).setFont(undefined, 'normal');
		//  doc.setFont(undefined, 'bold').text(currencyFormat(parseFloat(eMSaving)+parseFloat(fortifySaving), "$"), 70,246).setFont(undefined, 'normal'); 
		doc.setFont(undefined, 'bold').text("3 Year ROI", 120,246).setFont(undefined, 'normal');
		  doc.setFont(undefined, 'bold').text(currencyFormat((parseFloat(yearlySaving)+parseFloat(annualROI))*3, "$"), 170,246).setFont(undefined, 'normal');
	//  doc.setFont(undefined, 'bold').text(currencyFormat((parseFloat(eMSaving)+parseFloat(fortifySaving))*3, "$"), 170,246).setFont(undefined, 'normal'); 
	 doc.setTextColor("#000000");
		doc.setFont(undefined, 'bold').text("Date:___________________",10,267);
		doc.setFont(undefined, 'bold').text("Signature:___________________",135,267);
		// doc.addPage();
		
		doc.setFont(undefined, 'bold').text("Date:___________________",10,267);
		let intNo = quoteNumber.split("-")
		//window.open(doc.output('bloburl'))
		await axios.post(API_URL+"insert-quote-number",{initialNumber:parseInt(intNo[1]),quoteNumber:quoteNumber}).then((res)=>{
			if(res?.data?.status==200){
				getQuoteNumber();
				window.open(doc.output('bloburl'))
			}
		}).catch((e) => {
			console.log(e)
        })

		}
	}
	const [charDataupdate,setCharDataUpdate]=React.useState([]);
	React.useEffect(()=>{

	},[])
	
	function formatDigit(input) { 
		
		let str = input.toString(); 
		let [beforeDecimal, afterDecimal] = str.split('.'); 
		let formattedBeforeDecimal = beforeDecimal.replace(/(\d{9})(?=\d)/g, '$1,'); 
		if (afterDecimal) {
			afterDecimal = afterDecimal.slice(0, 2);   
		} 
		let formatted = afterDecimal ? `${formattedBeforeDecimal}.${afterDecimal}` : formattedBeforeDecimal;
		
		return formatted;
	}
	
	return(
		<>
			<div className="wrapper">
				<div className="main-content">
					<header>
						<img className="headerlogo" src={Logo} alt="img" />
					</header>
					<div className="quotenumber">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-6">
								</div>
								<div className="col-md-6">
									<div className="makefifty label-right">
										<div className="form-group">
											<label for="exampleInputEmail1">Quote Number</label>
											<input type="text" className="form-control" value={quoteNumber} readOnly={true} />
										</div>					
									</div>			
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="yellow-border"></div>
									<div className="yellow-border"></div>
								</div>
							</div>
						</div>
					</div>
					<div className="companyNumber">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-6">
									<div className="makefifty label-left">
										<div className="form-group">
											<label for="exampleInputEmail1">Company Name</label>
											<input type="text" className={companyNameError?"form-control error":"form-control"} onChange={handleCompanyName} placeholder="Company Name" />
										</div>	
									</div>
								</div>
								<div className="col-md-6">
									<div className="makefifty label-right">
										<div className="form-group">
											<label for="exampleInputEmail1">Covenant AE Name</label>
											<select className={covenantAENameError?"form-control error":"form-control"} onChange={handleAEName}>
											{
								  				aeOption.map((val, ind) => {
								  					return(
								  						<><option value={val?.id}>{val?.userName}</option></>
								  					)
								  				})
								  			}						  
											</select>
										</div>

										<div className="form-group">
											<label for="exampleInputEmail1">Date Created</label>
											{/* <input type="text" className="form-control" onChange={handleCreatedDate} placeholder="Date Created" /> */}
											<DatePicker
											className={createdDateError?"form-control error":"form-control"}
											selected={createdDate}
											onChange={handleCreatedDate}
											numberOfMonths={1}
											showOtherDays
											dateFormat="MM-dd-yyyy"
											placeholderText="Date Created"
											
											maxDate={new Date()}
											onKeyDown={(e) => {
										       e.preventDefault();
										    }}
											/>
										</div>

									</div>			
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">				
									<div className="yellow-border"></div>
								</div>
							</div>
						</div>
					</div>
					<div className="customerName">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-6">
									<div className="makefifty label-left">
										<div className="form-group">
											<label for="exampleInputEmail1">Customer Name</label>
											<input type="text" className={customerNameError?"form-control error":"form-control"} onChange={handleCustomerName} placeholder="Customer Name" />
										</div>

										<div className="form-group">
											<label for="exampleInputEmail1">Customer Title</label>
											<input type="text" className={customerTitleError?"form-control error":"form-control"} onChange={handleCustomerTitle} placeholder="Customer Title" />
										</div>

										<div className="form-group">
											<label for="exampleInputEmail1">Contact Email</label>
											<input type="text" className={customerContactError?"form-control error":"form-control"} onChange={handleCustomerContact} placeholder="Contact Email" />
										</div>	
									</div>
								</div>
								<div className="col-md-6">
									<div className="makefifty label-right">
										<div className="form-group">
											<label for="exampleInputEmail1">Technical Name</label>
											<input type="text" className={technicalNameError?"form-control error":"form-control"} onChange={handleTechnicalName} placeholder="Technical Name" />
										</div>

										<div className="form-group">
											<label for="exampleInputEmail1">Contact Email</label>
											<input type="text" className={technicalContactError?"form-control error":"form-control"} onChange={handleTechnicalConctact} placeholder="Contact Email" />
										</div>

										<div className="form-group">
											<label for="exampleInputEmail1">Start Date (Est.)</label>
											{/* <input type="text" className="form-control" onChange={handleStartDate} placeholder="Start Date (Est.)" /> */}
											<DatePicker
											className={startDateError?"form-control error":"form-control"}
											selected={startDate}
											onChange={handleStartDate}
											numberOfMonths={1}
											showOtherDays
											dateFormat="MM-dd-yyyy"
											placeholderText="Start Date (Est.)"
											
											onKeyDown={(e) => {
										       e.preventDefault();
										    }}
											/>
										</div>

									</div>			
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">				
									<div className="yellow-border"></div>
								</div>
							</div>
						</div>
					</div>
					<div className="cobwellName">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="cobwellProgram">
										<h2 className="headingh2">Covenant Cyber Assure Program <small></small><img src={BlackLogo} /></h2>
									 <p className='text-justify'> 
 The <strong> Covenant Cyber Assure program </strong> was developed and made possible through collaborations between Covenant Global, Microsoft, and strategic partners. It is designed with data security in mind. We aim to provide a direct path to a secure Microsoft 365 environment 
 for "higher risk" Covenant customers to reduce their cyber risk and cyber insurance costs.
 </p>
 <p>Covenant can help you achieve your security goals by migrating from your on-premises Exchange environment to Exchange Online and cyber-hardening your Microsoft 365 environment with our proprietary Fortify Identity (F1) & Device (F2) hardening configurations. The even better news
	 is that the Exchange migration and Fortify are fully funded through your participation in the program, ensuring a cost-effective solution for your security needs!
</p>
<p>
As an added value, we work closely with your team to review third-party digital assets, identify redundancies, and save additional costs by providing a consolidated, flexible approach to Microsoft licensing and cyber security management.
 </p>

										 </div>
								</div>			
							</div>
							<div className="row">
								<div className="col-md-12">				
									<div className="yellow-border">	
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="download-ROi">
						<div className="container-fluid">
							<div className="row" style={{"marginBottom":"55px"}}>
								<div className="col-md-12">
									<h3>Key Business Takeaways</h3>
									<ol>
										<li>Insurance Premium Reduction Opportunity</li>
										<li>Cost-Effective 365 Transition</li>
										<li>Complimentary Cloud Email Transition</li>
										<li>Enhanced Cyber Protection</li>
										<li>24/7 Security Monitoring</li>
									</ol>
								</div>
							</div>
						</div>
					</div>	
					<div className="letscompare">
						<div className="container-fluid breakpage">
							<div className="row">
								<div className="col-md-12" style={{"textAlign":"center"}}>
									<div className="cobwellProgram">
										<img src={graphicsDe} style={{width:"100%", maxWidth:"1200px"}}/>					
									</div>
								</div>
								 
								<div className="col-md-12" style={{"textAlign":"center"}}>
									<div className="cobwellProgram breakpage">
										<img src={graphicsDeS} style={{width:"100%", maxWidth:"1200px"}}/>					
									</div>
								</div>	
										
							</div>
						</div>
					</div>
					<div className="letscompare">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="cobwellProgram">
										<h2 className="headingh2">Let’s compare!</h2>					
									</div>
								</div>			
							</div>
							<div className="row">
								<div className="col-md-6">
									
								</div>
								<div className="col-md-6">
									<div className="row">
										<div className="col-md-12">
										</div>
									</div>									
								</div>
							</div>
							<div className="row">
								<div className="col-md-6"></div>
								<div className="col-md-6">
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label for="exampleInputEmail1">No. of Users</label>
												<InputMask mask="999999" className={"form-control"} maskChar={null} value={(noOfUsers)}  onChange={handleNoOfUsers} placeholder="No. of User" />
											</div>
										</div>
										<div className="col-md-4"></div>
										<div className="col-md-4"></div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-6">
									<div className="tableare">
										<table className="table table-bordered">
										  	<thead>
											  	<tr>
											  		<th>Current Environment</th>
											  		<th width="35%">Solution *</th>
											  		{/*<th width="20%">Monthly Cost *</th>*/}
											  		<th width="20%">Annual Cost</th>
											  	</tr>
										  	</thead>
										  	<tbody>
											  	<tr>
											  		<td>Productivity Applications</td>
											  		<td>
											  			<select className="form-control" onChange={(e) => handleSolutionOne(e, "label")}>
											  			<option value="">Select Solution</option>
											  			{
											  				currentEnvOption.map((val, ind) => {
											  					if(val?.parent_id == "1"){
											  						return(
												  						<><option value={val?.id}>{val?.solutionName}</option></>
												  					)
											  					}
											  					
											  				})
											  			}						  
														</select>
													</td>
											  		<td>
											  			<div className="input-group" >
													      <div className="input-group-addon" >$</div>  
													      <input type="text" maxLength="8" className={`form-control ${selectNone?.one ? "selectNone" :""} `} value={(solutionOneValueYearly)} onChange={(e) => handleSolutionOne(e, "value")} readOnly={isDisableOneCurrent} />
													    </div>
											  		</td>
											  	</tr>
											  	<tr>
											  		<td>Multi-Factor Authentication (MFA/2FA)</td>
											  		<td>
											  			<select className="form-control" onChange={(e) => handleSolutionTwo(e, "label")}>
											  			<option value="">Select Solution</option>
														  {
											  				currentEnvOption.map((val, ind) => {
											  					if(val?.parent_id == "2"){
											  						return(
												  						<><option value={val?.id}>{val?.solutionName}</option></>
												  					)
											  					}
											  				})
											  			}							  
														</select>
													</td>
											  		<td>
											  			<div className="input-group">
													      <div className="input-group-addon">$</div> 
													      <input type="text" maxLength="8" className={`form-control ${selectNone?.two ? "selectNone" :""} `} value={(solutionTwoValueYearly)} onChange={(e) => handleSolutionTwo(e, "value")} readOnly={isDisableTwoCurrent}  />
													    </div>
											  		</td>
											  	</tr>
											  	<tr>
											  		<td>Single Sign-On (SSO)</td>
											  		<td>
											  			<select className="form-control" onChange={(e) => handleSolutionThree(e, "label")}>
											  			<option value="">Select Solution</option>
														  {
											  				currentEnvOption.map((val, ind) => {
											  					if(val?.parent_id == "3"){
											  						return(
												  						<><option value={val?.id}>{val?.solutionName}</option></>
												  					)
											  					}
											  				})
											  			}							  
														</select>
													</td>
											  		<td>
											  			<div className="input-group">
													      <div className="input-group-addon">$</div>
													      <input type="text" maxLength="8" className={`form-control ${selectNone?.three ? "selectNone" :""} `} value={(solutionThreeValueYearly)}   onChange={(e) => handleSolutionThree(e, "value")} readOnly={isDisableThreeCurrent}/>
													    </div>
											  		</td>
											  	</tr>
											  	<tr>
											  		<td>Cloud Storage</td>
											  		<td>
											  			<select className="form-control" onChange={(e) => handleSolutionFour(e, "label")}>
											  			<option value="">Select Solution</option>
														  {
											  				currentEnvOption.map((val, ind) => {
											  					if(val?.parent_id == "4"){
											  						return(
												  						<><option value={val?.id}>{val?.solutionName}</option></>
												  					)
											  					}
											  				})
											  			}							  
														</select>
													</td>
											  		<td>
											  			<div className="input-group">
													      <div className="input-group-addon">$</div>
													      <input type="text" maxLength="8" className={`form-control ${selectNone?.four ? "selectNone" :""} `}  value={(solutionFourValueYearly)}   onChange={(e) => handleSolutionFour(e, "value")} readOnly={isDisableFourCurrent} />
													    </div>
											  		</td>
											  	</tr>
											  	<tr>
											  		<td>Chat</td>
											  		<td>
											  			<select className="form-control" onChange={(e) => handleSolutionFive(e, "label")}>
											  			<option value="">Select Solution</option>
														  {
											  				currentEnvOption.map((val, ind) => {
											  					if(val?.parent_id == "5"){
											  						return(
												  						<><option value={val?.id}>{val?.solutionName}</option></>
												  					)
											  					}
											  				})
											  			}							  
														</select>
													</td>
											  		<td>
											  			<div className="input-group">
													      <div className="input-group-addon">$</div>
													      <input type="text" maxLength="8" className={`form-control ${selectNone?.five ? "selectNone" :""} `} value={(solutionFiveValueYearly)}     onChange={(e) => handleSolutionFive(e, "value")} readOnly={isDisableFiveCurrent} />
													    </div>
											  		</td>
											  	</tr>
												  <tr>
											  		<td>Video Conference</td>
											  		<td>
											  			<select className="form-control" onChange={(e) => handleSolutionEleven(e, "label")}>
											  			<option value="">Select Solution</option>
														  {
											  				currentEnvOption.map((val, ind) => {
											  					if(val?.parent_id == "11"){
																	  
											  						return(
												  						<><option value={val?.id}>{val?.solutionName}</option></>
												  					)
											  					}
											  				})
											  			}							  
														</select>
													</td>
											  		<td>
											  			<div className="input-group">
													      <div className="input-group-addon">$</div>
													      <input type="text" maxLength="8" className={`form-control ${selectNone?.eleven ? "selectNone" :""} `} value={(solutionElevenValueYearly)}   onChange={(e) => handleSolutionEleven(e, "value")} readOnly={isDisableElevenCurrent} />
													    </div>
											  		</td>
											  	</tr>
											  	<tr>
											  		<td>Email Threat Protection</td>
											  		<td>
											  			<select className="form-control" onChange={(e) => handleSolutionSix(e, "label")}>
											  			<option value="">Select Solution</option>
														  {
											  				currentEnvOption.map((val, ind) => {
											  					if(val?.parent_id == "6"){
											  						return(
												  						<><option value={val?.id}>{val?.solutionName}</option></>
												  					)
											  					}
											  				})
											  			}						  
														</select>
													</td>
											  		<td>
											  			<div className="input-group">
													      <div className="input-group-addon">$</div>
													      <input type="text" maxLength="8" className={`form-control ${selectNone?.six ? "selectNone" :""} `}   value={(solutionSixValueYearly)} onChange={(e) => handleSolutionSix(e, "value")} readOnly={isDisableSixCurrent} />
													    </div>
											  		</td>
											  	</tr>
											  	<tr>
											  		<td>Endpoint Antivirus/AntiMalware (EDR/XDR)</td>
											  		<td>
											  			<select className="form-control" onChange={(e) => handleSolutionSeven(e, "label")}>
											  			<option value="">Select Solution</option>
														  {
											  				currentEnvOption.map((val, ind) => {
											  					if(val?.parent_id == "7"){
											  						return(
												  						<><option value={val?.id}>{val?.solutionName}</option></>
												  					)
											  					}
											  				})
											  			}							  
														</select>
													</td>
											  		<td>
											  			<div className="input-group">
													      <div className="input-group-addon">$</div>
													      <input type="text" maxLength="8" className={`form-control ${selectNone?.seven ? "selectNone" :""} `} value={(solutionSevenValueYearly)}  onChange={(e) => handleSolutionSeven(e, "value")} readOnly={isDisableSevenCurrent} />
													    </div>
											  		</td>
											  	</tr>
											  	<tr>
											  		<td>Endpoint Management (MDM)</td>
											  		<td>
											  			<select className="form-control" onChange={(e) => handleSolutionEight(e, "label")}>
											  			<option value="">Select Solution</option>
														  {
											  				currentEnvOption.map((val, ind) => {
											  					if(val?.parent_id == "8"){
											  						return(
												  						<><option value={val?.id}>{val?.solutionName}</option></>
												  					)
											  					}
											  				})
											  			}						  
														</select>
													</td>
											  		<td>
											  			<div className="input-group">
													      <div className="input-group-addon">$</div>
													      <input type="text" maxLength="8" className={`form-control ${selectNone?.eight ? "selectNone" :""} `} value={(solutionEightValueYearly)}   onChange={(e) => handleSolutionEight(e, "value")} readOnly={isDisableEightCurrent} />
													    </div>
											  		</td>
											  	</tr>
											  	<tr>
											  		<td>24x7 Security Operations Center (SOC) + MDR</td>
											  		<td>
											  			<select className="form-control" onChange={(e) => handleSolutionNine(e, "label")}>
											  			<option value="">Select Solution</option>
														  {
											  				currentEnvOption.map((val, ind) => {
											  					if(val?.parent_id == "9"){
											  						return(
												  						<><option value={val?.id}>{val?.solutionName}</option></>
												  					)
											  					}
											  				})
											  			}						  
														</select>
													</td>
											  		<td>
											  			<div className="input-group">
													      <div className="input-group-addon">$</div>
													      <input type="text" maxLength="8" className={`form-control ${selectNone?.nine ? "selectNone" :""} `}  value={(solutionNineValueYearly)}   onChange={(e) => handleSolutionNine(e, "value")} readOnly={isDisableNineCurrent} />
													    </div>
											  		</td>
											  	</tr>
												  <tr>
											  		<td>Backup</td>
											  		<td>
											  			<select className="form-control" onChange={(e) => handleSolutionTwelve(e, "label")}>
											  			<option value="">Select Solution</option>
														  {
											  				currentEnvOption.map((val, ind) => {
											  					if(val?.parent_id == "12"){
											  						return(
												  						<><option value={val?.id}>{val?.solutionName}</option></>
												  					)
											  					}
											  				})
											  			}						  
														</select>
													</td>
											  		<td>
											  			<div className="input-group">
													      <div className="input-group-addon">$</div>
													      <input type="text" maxLength="8" className={`form-control ${selectNone?.twelve ? "selectNone" :""} `}  value={(solutionTwelveValueYearly)}  onChange={(e) => handleSolutionTwelve(e, "value")} readOnly={isDisableTwelveCurrent} />
													    </div>
											  		</td>
											  	</tr>
											  	<tr>
											  		<td>Additional</td>
											  		<td>
											  			<select className="form-control" onChange={(e) => handleSolutionTen(e, "label")}>
											  			<option value="">Select Solution</option>
														  {
											  				currentEnvOption.map((val, ind) => {
											  					if(val?.parent_id == "10"){
											  						return(
												  						<><option value={val?.id}>{val?.solutionName}</option></>
												  					)
											  					}
											  				})
											  			}						  
														</select>
													</td>
											  		<td>
											  			<div className="input-group">
													      <div className="input-group-addon">$</div>
													      <input type="text" className="form-control"  maxLength="8"   value={(solutionTenValueYearly)} readOnly={true} />
													    </div>
											  		</td>
											  	</tr>
											  	{/*<tr>
											  		<td>Additional</td>
											  		<td >
											  			<div className="form-group">
													      <input type="text" className="form-control" onChange={(e) => handleAdditionalText(e)} value={additionalText} />
													    </div>
													</td>
											  		<td >
											  			<div className="input-group">
													      <div className="input-group-addon">$</div>
													      <CurrencyInput
																className="form-control"
															  	allowNegativeValue={false}
															  	decimalsLimit={2}
															  	value={additional}
															  	onValueChange={(e) => handleAdditional(e)}
															/>
													    </div>
													</td>
											  	</tr>*/}
										  	</tbody>
										</table>
										
									</div>
									<div className="downsides">
										<div className="row">
											<div className="col-md-6">
											</div>
											<div className="col-md-6">
												{/*<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="" for="exampleInputEmail1"></label>
															<div className="input-group">												  	
														      	<div className="input-group-addon">$</div>
														      	<input type="text" className="form-control" readOnly={true} value={totalMonthlyCost} />
														    </div>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="" for="exampleInputEmail1"></label>
															<div className="input-group">												  	
														      <div className="input-group-addon">$</div>
														      <input type="text" className="form-control" readOnly={true} value={totalYearlyCost} />
														    </div>
														</div>
													</div>
												</div>*/}
												
												<div className="row">
													<div className="col-md-6">
													
													</div>
													<div className="col-md-6">
														<div className="form-group tttl">
															<label className="" for="exampleInputEmail1">Total Annual</label>
															<div className="input-group">									  	
														      <div className="input-group-addon">$</div>
														      <input type="text" className="form-control" readOnly={true} value={totalUsersYearlyCost} />
														    </div>
														</div>
													</div>
													</div>

											</div>	
															
										</div>
									</div>
									 
								</div>
								
								<div className="col-md-6">

									<div className="microsft-box">
										<div className="row">
											<div className="col-md-9">
												<div className="form-group">
													<label for="exampleInputEmail1">Updated Environment</label>
													<select className="form-control" onChange={(e) => handleAltSolutionOne(e, "label")}>
														<option value="">Select Updated Environment</option>
													  {
											  				updatedEnvOption.map((val, ind) => {
											  					return(
											  						<><option value={val?.id}>{val?.solutionName}</option></>
											  					)
											  				})
											  			}						  
													</select>
												</div>
												
											</div>
											<div className="col-md-3">
												<div className="row">
													{/*<div className="col-md-6">									
														<div className="form-group">
															<label for="exampleInputEmail1">Monthly Cost</label>
															<CurrencyInput
																className="form-control"
															  	allowNegativeValue={false}
															  	decimalsLimit={2}
															  	value={altSolutionOneValueMonthly}
															  	onValueChange={(e) => handleAltSolutionOne(e, "value")}
															/>
														</div>									
													</div>*/}
													<div className="col-md-12">	
													<label for="exampleInputEmail1">Annual Cost</label>								
														<div className="input-group">
															
															<div className="input-group-addon">$</div>
															<input type="text" className="form-control" value = {altSolutionOneValueYearly} readOnly={true} />
														</div>									
													</div>
												</div>							
											</div>
										</div>
										<div className="row">
											<div className="col-md-9">
												<div className="form-group">
													<label for="exampleInputEmail1">24x7 Security Operations Center (SOC) + MDR</label>
													<select className="form-control" onChange={(e) => handleSOC(e)}>
														<option value="0">None</option>
														<option value="120">BlackPoint Cyber</option>
													</select>
												</div>
												
											</div>
											<div className="col-md-3">
												<div className="row">
													<div className="col-md-12">
														<label for="exampleInputEmail1">&nbsp;</label>								
														<div className="input-group">
															<div className="input-group-addon">$</div>
															<input type="text" className={`form-control ${soc=="0.00" ? "selectNone" :""} `} value = {soc} readOnly={true} />
														</div>									
													</div>
												</div>							
											</div>
										</div>
										<div className="row">
											<div className="col-md-9">
												<div className="form-group">
													<label for="exampleInputEmail1">Backup (365 Tenant)</label>
													<select className="form-control" onChange={(e) => handleBackup(e)}>
														<option value="0">None</option>
														<option value="48">Acronis</option>
													</select>
												</div>
												
											</div>
											<div className="col-md-3">
												<div className="row">
													<div className="col-md-12">
														<label for="exampleInputEmail1">&nbsp;</label>								
														<div className="input-group">
															<div className="input-group-addon">$</div>
															<input type="text" className={`form-control ${backup=="0.00" ? "selectNone" :""} `} value = {backup} readOnly={true} />
														</div>									
													</div>
												</div>							
											</div>
										</div>
										<div className="row moveitdown">
											<div className="col-md-9">
												<div className="textareBlock">
													<div className="form-control" style={{"height":"267px", overflowY:"scroll"}} dangerouslySetInnerHTML={{__html: textAreaBlock}} />
												</div>
											</div>
											<div className="col-md-3" style={{"paddingTop":"70px"}}>
												<div className="microsoft-logo">
													<img src={MSLogo} alt="img" />
												</div>

											</div>
										</div>

									</div>
									<div className="downsides">
										<div className="row">
											<div className="col-md-6">
											</div>
											<div className="col-md-6">
												{/*<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="" for="exampleInputEmail1"></label>
															<div className="input-group">												  	
														      <div className="input-group-addon">$</div>
														      <input type="text" className="form-control" value={altTotalMonthlyCost}readOnly={true} />
														    </div>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="visibiltyhide" for="exampleInputEmail1"></label>
															<div className="input-group">												  	
														      <div className="input-group-addon">$</div>
														      <input type="text" className="form-control" value={altTotalYearlyCost}readOnly={true} />
														    </div>
														</div>
													</div>
												</div>*/}
												<div className="row">
													<div className="col-md-6">
														{/*<div className="form-group">
															<label className="" for="exampleInputEmail1">Total Monthly</label>
															<div className="input-group">												  	
														      <div className="input-group-addon">$</div>
														      <input type="text" className="form-control" value={altTotalUsersMonthlyCost}readOnly={true} />
														    </div>
														</div>*/}
													</div>
													<div className="col-md-6">
														<div className="form-group tttl">
															<label className="" for="exampleInputEmail1">Total Annual </label>
															<div className="input-group">									  	
														      <div className="input-group-addon">$</div>
														      <input type="text" className="form-control" value={altTotalUsersYearlyCost}readOnly={true} />
														    </div>
														</div>
													</div>
												</div>

											</div>						
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
								<div className="d-flex align-item-center">
								<center><h2>Security Posture  </h2></center>
								</div>
													<Line ref={chartRef} data={ChartDataDisplaydata} options={ChartDataDisplayOptions} />

								</div>
								<div className="col-md-6"></div>
								</div>	

						</div>
					</div>
					<div className="timetosee">
						<div className="container-fluid">
							
							<div className="row">
								<div className="col-md-12">
									<h2 className="headingh2">Time to see the ROI!</h2>
								</div>
							</div>

							<div className="row">
								<div className="col-md-5">
									<h3 className="text-center">Cyber Policy ROI</h3>
									<div className="cyber-policy">
										
										<div className="makefifty label-left">
											<div className="form-group">
												<label for="exampleInputEmail1">Cyber Liability Premium (Current)*</label>
												<div className="input-group">												  	
											      <div className="input-group-addon">$</div>
											      <InputMask mask="99999999" className={"form-control"} maskChar={null} onChange={handleCLP} />
											    </div>
											</div>	
										</div>

										<div className="makefifty label-left">
											<div className="form-group">
												<label for="exampleInputEmail1">API Policy Discount*</label>
												<div className="input-group">												  	
											      <div className="input-group-addon">%</div>
											      <input type="text" className="form-control" onChange = {handleApiPolicyDis}
									             value={apiPolicyDis} onKeyPress={handleFloatNumber}/>
											    </div>
											</div>	
										</div>
										<div className="makefifty label-left">
											<div className="form-group">
												<label for="exampleInputEmail1">Est. Policy Discount*</label>

												<div className="group-fifty">
													<span className="printvalue">{(apiPolicyDisValue?"- ":"")+currencyFormat(apiPolicyDisValue?apiPolicyDisValue:0)}</span>
													<div className="input-group">												  	
												      	<select className={lowRiskError?"form-control error":"form-control"} onChange={handleLowRisk}>
											  				<option value="">Select Est. Policy Discount</option>
											  				<option value="20">Level A Low Risk 20%</option>	  
											  				<option value="10">Level B Medium Risk 10%</option>	  
											  				<option value="0">Level C High Risk 0%</option>	  
														</select>
												    </div>
												    <span className="printvalue">{(lowRiskPremiumVal?"-":"")+currencyFormat(lowRiskPremiumVal?lowRiskPremiumVal:0)}</span>
												</div>
											</div>	
										</div>
										<div className="makefifty label-left">
											<div className="dislaimerPar">
												<p><strong>**Disclaimer**</strong> - Cyber Premium savings are estimated and not guaranteed. There are several factors and variables that determine insurance "risk", Risk factors and premiums. Cyber Assure Program can provide and updated premium upon completion of the Cyber Assure engagement.
												</p>
											</div>
										</div>
										<div className="makefifty label-left onlyvalueswe">
											<div className="form-group">
												<label for="exampleInputEmail1">Annual Policy Savings (ROI)</label>
												<div className="group-fifty onlyvalue">
													<span className="printvalue">{currencyFormat(annualROI?annualROI:0)}</span>							
												</div>
											</div>	
										</div>

									</div>
								</div>
								<div className="col-md-2">
									<div className="show-plus">
										+
									</div>
								</div>
								<div className="col-md-5">
									
									<h3 className="text-center">Licensing ROI</h3>
									<div className="cyber-policy Licensing">
										{/*<div className="makefifty label-left">
											<div className="form-group">
												<label for="exampleInputEmail1">Monthly Savings</label>
												<div className="group-fifty onlyvalue">
													<span className="printvalue">{currencyFormat(monthlySaving, "$")}</span>							
												</div>
											</div>	
										</div>*/}
										<div className="makefifty label-left">
											<div className="form-group">
												<label for="exampleInputEmail1">Annual Savings (ROI)</label>
												<div className="group-fifty onlyvalue">
													<span className="printvalue">{currencyFormat(yearlySaving, "$")}</span>							
												</div>
											</div>	
										</div>
									</div>
										<h3 className="text-center">Projected ROI After Cyber Assure Completion</h3>
										<div className="cyber-policy Licensing bottompage">
											<div className="row">
												<div className="col-md-9">
													<div className="makefifty label-left">
														<div className="form-group">
															<label for="exampleInputEmail1">Exchange Migration Savings</label>
															<div className="group-fifty onlyvalue">
																<span className="printvalue">{currencyFormat(eMSaving,"$")}</span>							
															</div>
														</div>	
													</div>
													<div className="makefifty label-left">
														<div className="form-group">
															<label for="exampleInputEmail1">Fortify Savings</label>
															<div className="group-fifty onlyvalue">
																<span className="printvalue">{currencyFormat(fortifySaving,"$")}</span>							
															</div>
														</div>	
													</div>
													<div className="makefifty label-left">
														<div className="form-group">
															<label className="makeBold" for="exampleInputEmail1">Cyber Assure Total Savings</label>
															<div className="group-fifty onlyvalue">
																<span className="printvalue">{currencyFormat((parseFloat(eMSaving)+parseFloat(fortifySaving)),"$")}</span>							
															</div>
														</div>	
													</div>
												</div>
												<div className="col-md-2">
													<div className="microsoft-logo" style={{"minHeight": "80px"}}>
														<img src={MSLogo} alt="img" />
													</div>
												</div>
											</div>
											
										</div>

									
								</div>
							</div>


						</div>
					</div>
					<div className="total-cyber">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="form-group">
										<label for="exampleInputEmail1">Notes:</label>
										<textarea rows="4" cols="50" className={"form-control"} onChange={handleNotes} />
									</div>	
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<h2>Projected ROI After Cyber Assure Completion</h2>
									<div className="fourBlocks">
										<ul>
											<li>1 Year ROI</li>
											<li>{currencyFormat(parseFloat(yearlySaving)+parseFloat(annualROI)+parseFloat(eMSaving)+parseFloat(fortifySaving), "$")}</li>
											{/*<li>{currencyFormat(parseFloat(eMSaving)+parseFloat(fortifySaving), "$")}</li>*/}
											<li>3 Year ROI</li>
											{/*<li>{currencyFormat((parseFloat(yearlySaving)+parseFloat(annualROI)+parseFloat(eMSaving)+parseFloat(fortifySaving))*3, "$")}</li>*/}
											<li>{currencyFormat((parseFloat(yearlySaving)+parseFloat(annualROI))*3, "$")}</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="download-ROi">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="buttons">
										<button type="button" onClick={generatePDF} className="btn btn-primary btn-lg">
								          <i className="fa fa-cloud-upload" aria-hidden="true"></i> DOWNLOAD ROI
								        </button>
									</div>
								</div>
							</div>
						</div>
					</div>
												
				</div>
			</div>
		</>
	)
}

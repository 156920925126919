import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/custom.css"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RoiCalculator from './component/roiCalculator'; 
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RoiCalculator />} exact={true} /> 
      </Routes>
    </BrowserRouter>
    
  );
}

export default App;
 